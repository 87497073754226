import React from "react";
import { Container, Row } from "react-bootstrap";

import "./index.css";

import { FeaturesDataModel } from "../../Data";

export default class Features extends React.Component {
  render() {
    return (
      <section className="feature">
        <div className="heading">
          Business banking has a new address,
          <div className="heading-child">
            and it is{" "}
            <span id="js-rotating">
              cheaper!, easier!, digital!, virtual!, reliable!
            </span>
          </div>
        </div>
        <Container className="feature-card-container">
          <Row className="card-container">
            {FeaturesDataModel.map((cardData, idx) => (
              <div
                className="card border-0 col-12 col-md-4 mb-3 mt-3 d-flex align-items-stretch"
                style={{ borderRadius: "20px" }} key={idx}
              >
                <div className="card-body features-card d-flex flex-column">
                  <h6>{cardData.tag}</h6>
                  <h4>{cardData.title}</h4>
                  <span />
                  <div className="mt-auto">
                    <p>{cardData.subtitle}</p>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </section>
    );
  }
}
