import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../Components/TableComponents/Header";
import TableSection1 from "../../Components/TableComponents/TableSection1";
import TableSection2 from "../../Components/TableComponents/TableSection2";
import TableSection3 from "../../Components/TableComponents/TableSection3";
import Form from "../../Components/TableComponents/Form";
import Footer from "../../Components/Footer";

const Table = () => {

  const [displayForm, setDisplayForm] = React.useState(false);  // Waitlist form modal

  return (
    <div>
      <Helmet>
        <title>TABLE by SALT</title>
      </Helmet>
      <Header displayForm={displayForm} setDisplayForm={setDisplayForm} />
      <TableSection1 />
      <TableSection2 />
      <TableSection3 displayForm={displayForm} setDisplayForm={setDisplayForm} />
      <Form displayForm={displayForm} setDisplayForm={setDisplayForm} />
      <Footer table={true} />
    </div>
  );
};

export default Table;
