import React from "react";
import {
  Container,
  Row,
  Col
} from "react-bootstrap";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import "./index.css";
import NewsletterBanner from "../../Assets/newsletter.png";

import { SALTLinks } from "../../Data";

export default class NewsletterSection extends React.Component {
  render() {
    return (
      <div className="newsletter-container">
        <Container>
          <Row>
            <Col className={"order-2 order-lg-1 col-12 col-lg-8 mt-5 mt-lg-0"}>
              <h3>Psst..Wanna read something good?</h3>
              <p>
                We share interesting reads on things we learned and thought it
                can help you too. <b>We don't spam</b>. We just like sharing thoughts
                on things we are incredibly passionate about - business and
                finance.
              </p>
              <div className="input-wrapper mt-5">
                <MailchimpSubscribe url={SALTLinks.newsletter}/>
              </div>
            </Col>
            <Col className={"order-1 order-lg-2 col-12 col-lg-4"}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: "250px",
                  maxHeight: "600px",
                  padding: "25px",
                  borderRadius: "12px",
                  background: "url(" + NewsletterBanner + ")",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
