import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import "./index.css";

export default class TableSection1 extends React.Component {
  render() {
    return (
      <>
        <div className="table-section1">
          <Container>
            <Row>
              <Col className="col-12 mt-5 mb-5 mt-lg-0 mb-lg-0">
                <div className="table-section1-subtitle">
                  What do we bring to your table?
                </div>
                <div className="table-section1-title">
                  Clarity, assistance and convenience.
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
