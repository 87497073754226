import React from "react";
import { Container } from "react-bootstrap";

import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";

export default class TOS extends React.Component {
  render() {
    return (
      <div>
        <NavigationBar/>
        <Container 
          style={{ color: "#808294", margin: "100px auto 100px auto" }}
        >
          <div className="row">
            <div className="text-center">
              <h2>
                <strong>TERMS OF SERVICE</strong>
              </h2>
              <p></p>
              <p>
                <strong>POZIOM VENTURES PRIVATE LIMITED ( SALT )</strong>
              </p>
              <p></p>
              <p>Last updated: 2021-06-01</p>
            </div>
            <p>
              1.&nbsp;<strong>Introduction</strong>
            </p>
            <p>
              These Terms of Service (“Terms”, “Terms of Service”) govern your
              use of the Salt Services&nbsp;(together or individually “Service”)
              operated by&nbsp;<strong>Poziom Ventures Private Limited</strong>
              &nbsp;(interchangeably “Company”, “we”, “our”, “us”, “Salt”, which
              shall, to the extent permitted by all Applicable Laws, include
              Poziom Ventures Private Limited and its Group Companies,
              affiliates, partners, Service Providers, subsidiaries and parent
              company).
            </p>
            <p>
              Our Privacy Policy also governs your use of our Service and
              explains how we collect, safeguard and disclose information that
              results from your use of our services.
            </p>
            <p>
              Your agreement with us includes these Terms and our Privacy Policy
              (“Agreements”). You acknowledge that you have read and understood
              Agreements, and agree to be bound of them.
            </p>
            <p>
              If you do not agree with (or cannot comply with) Agreements, then
              you may not use the Service, but please let us know by emailing
              at&nbsp;<strong>support@salt.pe</strong>&nbsp;so we can try to
              find a solution. These Terms apply to all visitors, users and
              others who wish to access or use Service.
            </p>
            <p>
              By opening a Salt Account or accessing or using any part of the
              Services, you (the “Customer”, “Merchant”, “you”/ “your”, “any
              authorized user”) accept, and agree to become bound by these Terms
              and Conditions. In particular, in you agree to be bound by the
              Jurisdiction Specific Terms that are relevant to the Account
              Jurisdiction (as specified Section 3.1 below) that you contract
              with. Besides, you also agree to comply with and be bound by the
              Privacy Policy and Cookies Policy and such other policies and
              guidelines, notices, circulars and announcements from time to time
              issued by us to you, all of which shall form part of these Terms
              and Conditions.
            </p>
            <p>
              Notwithstanding that we may notify you of any major changes we
              made to these Terms and Conditions electronically, you shall be
              responsible for regularly reviewing these Terms and Conditions. At
              any time, you can view our current Terms and Conditions on our
              Website and/or applications.&nbsp;&nbsp;All amendments,
              supplements and revisions shall be effective upon publication on
              our Website and/or applications.&nbsp;&nbsp;When you use the
              Services after our publication of any such amendment, supplement
              or revision, you are deemed to have read and agreed to the latest
              version of our Terms and Conditions, Privacy Policy, Cookies
              Policy&nbsp;and such other policies and guidelines, notices,
              circulars and announcements from time to time issued by us to
              you.&nbsp;&nbsp;If you do not agree to such amendments,
              supplements or revisions, you shall close your Account and stop
              using any of the Services.
            </p>
            <p>
              These Term and Conditions shall be effective and legally binding
              on the date when (a) Customer creates an Account, affirmatively
              accepts the Agreement in writing or electronically; or (b)
              otherwise uses the Services.&nbsp;&nbsp;Subsequently, these Terms
              and Conditions shall be continued until your termination of use of
              the Account or the Services. Your acceptance of these Terms and
              Conditions shall serve as confirmation that you fully understand
              and accept to all the provisions in these Terms and Conditions. If
              you do not understand any of these Terms and Conditions, you are
              advised to obtain independent legal advice.
            </p>
            <p>
              You acknowledge that you and your use of our Services shall be
              subject to all mandatory provisions of the Applicable Laws. You
              shall solely be responsible for understanding and complying with
              any and all laws, rules and regulations of your home country and
              the Account Jurisdiction that may be applicable to you in
              connection with your use of our Services, which shall include,
              without limitation, laws governing payment services, anti-money
              laundering or anti-terrorist financing requirements, consumer
              protection, data protection laws, anti-discrimination, gambling,
              false advertising, illegal sale or purchase or exchange of any
              goods or services, and those related to export/import activity,
              taxes or foreign currency exchange or licensing.
            </p>
            <p>
              Our obligations under this Agreement shall be conditional on our
              acceptance of you as a Customer at our sole discretion. Please
              specifically refer to Section 5 for Customer registration
              requirements and Section 12 for Customer Obligations and
              Warranties for details.
            </p>
            <p>
              Salt is neither a bank nor a credit institution, and we are not
              permitted to pay interest to you on Funds held in the Payment
              Account. Our Services might offer features or benefits of a bank
              account in partnership with respective Service Providers and Bank.
              Please see Section 4 for details.
            </p>
            <p>
              We may at any time close, suspend or limit your access to your use
              of the Services if we suspect that you may have committed any
              breach to this Agreement or any other agreement you enter into
              with us or pursuant to any proprietary monitoring used by us when
              assessing the risk associated with your transaction activity.
              Please see Section 10 for details. We may also limit your access
              to Funds per the instructions of law enforcement or our regulators
              or when we consider necessary and appropriate.
            </p>
            <p>
              We may close your Account if your account becomes inactive. Please
              see Section 11 for details.
            </p>
            <p>
              Protecting your privacy is very important to us. Please review
              our&nbsp;<strong>Privacy Policy</strong>&nbsp;and Cookies Policy
              in order to better understand our commitment to maintaining your
              privacy, as well as our use and disclosure of your
              Information.&nbsp;&nbsp;You shall only enter into this Agreement
              if you fully understand and agree to be bound by the Privacy
              Policy and the Cookies Policy.
            </p>
            <p>
              2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Definitions</strong>
            </p>
            <p>
              In the context of this Agreement, the following terms shall have
              the following meaning:
            </p>
            <p>
              2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Account
              Jurisdiction” means the jurisdiction where we receive the Fund
              from the Platform or the sender.
            </p>
            <p>
              2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Agreement”&nbsp;means&nbsp;these
              Terms and
              Conditions,&nbsp;all&nbsp;exhibits,&nbsp;referenced&nbsp;documents,&nbsp;attachments
              and such other policies and guidelines, notices, circulars and
              announcements from time to time issued by us to you, including the
              Privacy Policy and the Cookies Policy.
            </p>
            <p>
              2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Applicable
              Law(s)” means any law, regulation or generally accepted practices
              or guidelines in the Account Jurisdiction, or any other
              jurisdictions applicable to your use of the Services, which shall
              include, without limitation, laws governing payment services,
              anti-money laundering or terrorist financing requirements,
              consumer protection, data protection laws, anti-discrimination,
              gambling, false advertising, illegal sale or purchase or exchange
              of any goods or services, and those related to export/import
              activity, taxes or foreign currency transactions, or licensing.
              Please further refer to the Jurisdiction Specific Terms.
            </p>
            <p>
              2.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Approved
              Payee” means a natural or legal person approved by us or our
              partners as payee of the Funds, which shall include, not limited
              to, an European Union Tax Administration, third party service
              companies, etc. To avoid any doubts, the Approved Payee shall in
              no event be a Consumer.
            </p>
            <p>
              2.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Beneficiary
              Account” means your local beneficiary bank account into which you
              wish to receive Balance through the Services, as specified by you
              through your Account.&nbsp;&nbsp;For the avoidance of doubt, you
              shall be the sole account holder of the Beneficiary Account.
            </p>
            <p>
              2.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Business Day”
              means a calendar day with the exception of Saturdays, Sundays and
              public holidays in the Account Jurisdiction, on which the payment
              infrastructures of the Account Jurisdiction are open and banks
              carry out their regular business activities.
            </p>
            <p>
              2.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Buyer” means a
              person or entity that purchases your product or service from you
              or from a Platform.
            </p>
            <p>
              2.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Consumer”
              means a natural person, who uses payment services for personal
              needs outside of his/her business, commercial or professional
              needs or activities, or a consumer as defined by the Applicable
              Law in the Account Jurisdiction. Please further refer to the
              Jurisdiction Specific Terms.
            </p>
            <p>
              2.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Customer” or
              “Merchant” means you, the individual or legal entity who enters
              into this Agreement and in whose name the Account is registered.
            </p>
            <p>
              2.10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“European Economic Area” or
              “European Union” means the region made up of the countries which
              have entered into the European Economic Area Agreement from time
              to time.
            </p>
            <p>
              2.11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“European Union Tax
              Administration” means any administrative organization within the
              European Union responsible for collection of value added tax for a
              relevant country in the European Union.
            </p>
            <p>
              2.12&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Fees” are the charges payable
              by you to us for using the Services which are more particularly
              specified in the Account held by you.
            </p>
            <p>
              2.13&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Funds”means the funds
              beneficially owned by you for conduct of the Transactions.
            </p>
            <p>
              2.14&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Group Companies” refers
              to any of the entities specified in Section 3, and any other
              affiliates or subsidiary companies and/or holding companies under
              the Salt brand which provides all or any part of our Services
              described in this Agreement.
            </p>
            <p>
              2.15&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Inbound Payment(s)” means the
              funds received in the designated Payment Account from approved
              Platforms or senders.
            </p>
            <p>
              2.16&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Jurisdiction Specific Terms”
              mean the terms set out in Exhibit A that are applicable to your
              Account Jurisdiction and which forms part of this Agreement.
            </p>
            <p>
              2.17&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Outbound Payment(s)”
              means a payment from the Payment Account to an Approved Payee upon
              an Outbound Payment Order.
            </p>
            <p>
              2.18&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Outbound Payment Order(s)”
              means any instruction by the Customer to us &nbsp;requesting the
              transfer of funds to an Approved Payee.
            </p>
            <p>
              2.19&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Outbound Transaction(s)” means
              the action, initiated by you, of transferring Funds from you to an
              Approved Payee pursuant to the Applicable Laws or your agreement
              with the Approved Payee from your Payment Account.
            </p>
            <p>
              2.20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Payment Account” means an
              account designated by us to you that holds your Balance.
            </p>
            <p>
              2.21&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Poziom Ventures Private
              Limited”, “Poziom Inc.”, “Poziom”, “Salt”, “we”, “us” or “our”
              means the applicable contracting entity as specified in Section 3
              hereof.
            </p>
            <p>
              2.22&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Account” means your online
              account where you register for our Services and make ongoing use
              of the Services.
            </p>
            <p>
              2.23&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Balance” means the balance of
              Funds as from time to time shown in your Account.
            </p>
            <p>
              2.24&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Account Credentials” means the
              Payment Account credentials (bank account number, routing number,
              IBAN, etc.) provided to you by us solely for the purpose of using
              the Services.
            </p>
            <p>
              2.25&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Services” means all services
              including but not limited to payment, banking and related services
              provided by Salt according to this Agreement, and “Service” shall
              mean any of them.
            </p>
            <p>
              2.26&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Salt website” or “Website”
              means our website available at https://Salt.pe/
            </p>
            <p>
              2.27&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Platform” means (i) an
              e-commerce marketplace or other third party selling platform; (ii)
              approved by Salt for use with Services; and (iii) where you are a
              seller of goods or services pursuant to a written agreement
              between you and the Platform.
            </p>
            <p>
              2.28&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Platform Transaction” means the
              action, initiated by the Platform, of transferring Funds to which
              you are entitled pursuant to your agreement with the Platform to
              your Payment Account.
            </p>
            <p>
              2.29&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Service Providers” shall
              include banks, payment service providers, clearing networks and
              other third-party payment processing services from time to time
              used by Salt in the course of provision of the Services.
            </p>
            <p>
              2.30&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Transaction” refers to either
              (i) a Platform Transaction or (ii) a Withdrawal Transaction ;
              (iii) an Outbound Transaction.
            </p>
            <p>
              2.31&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“VAT” means value added tax
              within the European Union or other jurisdictions where value added
              tax is imposed.
            </p>
            <p>
              2.32&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Withdrawal Instruction(s)”
              means, following receipt of Funds through a Platform Transaction,
              an instruction by a Customer instructing Salt to execute a
              Withdrawal Transaction.
            </p>
            <p>
              2.33&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Withdrawal Transaction(s)”
              means, following the action of a Withdrawal Instruction, the
              payment or transfer of Funds from the Payment Account to your
              designated Beneficiary Account or Approved Payee.
            </p>
            <p>
              3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>
                Contracting Entity, Governing Law and Communication
              </strong>
            </p>
            <p>
              3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“Salt,” “we,”
              and “our” in this Agreement refer to the contracting entity listed
              in the chart below that corresponds to the Account Jurisdiction
              associated with your Payment Account.&nbsp;&nbsp;In the event that
              you open multiple Payment Accounts with us in different Account
              Jurisdictions, you will be deemed to have entered into agreements
              with each of us in the Account Jurisdictions where you have
              Payment Account with us.&nbsp;&nbsp;Please also refer to the
              Jurisdiction Specific Terms when you read this Agreement.
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Account
              Jurisdiction: India
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Contracting
              Entity: Poziom ventures Private Limited
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Address for
              Notices: 512/10, Service Lane, Outer Ring Road, Dhaaruni
              residency, Mahadevapura, Bangalore, Karnataka, India, 560048
            </p>
            <p>Governing Law: India</p>
            <p>
              3.2&nbsp;&nbsp;&nbsp;&nbsp; You may contact Customer Support at
              any time by sending a message though the contact links on our
              website (https://salt.pe/) , via e-mail at&nbsp;
              <a href="mailto:support@salt.pe">support@salt.pe</a>. By using our
              Service, you agree to subscribe to newsletters, marketing or
              promotional materials and other information we may send. However,
              you may opt out of receiving any, or all, of these communications
              from us by following the unsubscribe link or by emailing at&nbsp;
              <a href="mailto:support@salt.pe">support@salt.pe</a>.
            </p>
            <p>
              3.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You acknowledge and
              confirm that we may provide notice or other service related
              information to you by posting it on the website(s) (which shall
              include, without limitation, in the webpage in your Account),
              emailing it to the email address listed in your Account, mailing
              it to the street address listed in your Account, calling you by
              phone, or sending you a “text” / SMS message. You must have
              internet access and an e-mail account to receive communications
              and information relating to Services.&nbsp;&nbsp;You further
              confirm that such notices shall be deemed to have received by you
              upon our issuance to you or upon publication on the
              website(s).&nbsp;&nbsp;&nbsp;You may request a copy of any legally
              required disclosures (including this Agreement) from us and we
              will provide this to you in a form which allows you to store and
              reproduce the information (for example, by e-mail).
            </p>
            <p>
              4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Salt Service Overview</strong>
            </p>
            <p>
              4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We provides you, the
              Customer with, a payment service that allows you to:
            </p>
            <p>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>
                <em>Receive Inbound Payment</em>
              </strong>
              &nbsp;from approved Platforms or senders to a designated Payment
              Account;
            </p>
            <p>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>
                <em>Withdraw Funds</em>
              </strong>
              &nbsp;from the Payment Account to your local Beneficiary Account
              in pursuant to Withdrawal Instruction or this Agreement;
            </p>
            <p>
              (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>
                <em>Initiate Outbound Payment</em>
              </strong>
              &nbsp;from the Payment Account to an Approved Payee; and
            </p>
            <p>
              (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>
                <em>Report transaction data</em>
              </strong>
              &nbsp;in some circumstances for regulatory compliance purposes as
              required to receive foreign currency.
            </p>
            <p>
              (e )&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Other services</strong>&nbsp;including but not limited to
              payments, banking, documentation, compliances etc.
            </p>
            <p>
              4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Services may be
              subject to certain limits and may not be available in certain
              jurisdiction, depending on:
            </p>
            <p>
              (a)&nbsp;&nbsp;&nbsp;the location of you, the Platform and/or
              senders/originators of Funds;
            </p>
            <p>
              (b)&nbsp;&nbsp;&nbsp;applicable regulatory requirements in the
              Account Jurisdiction;
            </p>
            <p>
              (c)&nbsp;&nbsp;&nbsp;&nbsp;the Jurisdiction Specific Terms
              applicable to the Account Jurisdiction; and
            </p>
            <p>
              (d)&nbsp;&nbsp;&nbsp;other similar factors determined by us and
              our partners acting reasonably.
            </p>
            <p>
              4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In order to
              provide our Services, We may rely on its Group Companies,
              financial institutions, third party banking counterparts, and/or
              payment providers, all of which are regulated institutions and/or
              comply with the local laws.
            </p>
            <p>
              4.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Salt is not a
              bank or a credit institution and it does not accept any deposit
              from you nor pay any interest to you on your Funds/ Balance. By
              accepting these Terms and Conditions you acknowledge that we are
              authorized to retain any interest that arises with respect to the
              sum of any Funds held in the bank account(s). You further
              acknowledge that the Payment Account does not qualify as a deposit
              account as defined by relevant laws and regulations, and thus
              Funds held in your Account or in the process of Transaction may
              not be insured. However, we will strictly adhere to applicable
              requirements that ensure the liquidity and protection of Funds
              held on your behalf.&nbsp;&nbsp;We may, at its sole and absolute
              discretion, settle your Balance to your Beneficiary Account if the
              Balance is not withdrawn by you for a period from time to time
              specified by us in accordance with the Applicable Laws.
            </p>
            <p>
              4.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Salt shall be
              considered as an independent contractor that provides the Services
              to the Customers. Salt shall in no event act as a fiduciary,
              trustee or escrow holder on your behalf.&nbsp;&nbsp;Subject to the
              specific terms applicable to the Account Jurisdiction, Salt shall
              act solely as an agent for you and/or the Approved Payee in
              respect of the Funds.
            </p>
            <p>
              4.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You shall in no
              event allow the Platform any right to debit the Payment Account
              and you shall be liable to Salt for any debits made on such
              account by Platform or any other third party without Salt’s prior
              written approval.&nbsp;&nbsp;Your failure to observe this Section
              shall be considered to be a material breach of this Agreement and
              we may terminate this Agreement forthwith without compensation.
            </p>
            <p>
              5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Your Account</strong>
            </p>
            <p>
              5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Salt Accounts.&nbsp;</strong>Theuse of Salt Services
              requires your registration of an Account. Your rights to this
              Account are limited by this Agreement. A business or individual
              engaging in business activities approved by us from time to time
              may apply to use the Services via our website by registration of
              an Account. As part of the application process, you will need to
              accept this Agreement (as defined in Section 2.2) and you may
              further be asked to confirm your acceptance to other arrangements
              that we may from time to time offer to you. You agree to provide
              us with true, accurate and complete information when you register
              for an Account. You will be able to see relevant information in
              your Account in pursuance to the personal data access rights
              provided under relevant data protection laws.
            </p>
            <p>
              5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Eligibility.</strong>&nbsp;To be eligible to open the Salt
              Account:
            </p>
            <ul>
              <li>
                When you create an account with us, you guarantee that you are
                above the age of 18, and that the information you provide us is
                accurate, complete, and current at all times. Inaccurate,
                incomplete, or obsolete information may result in the immediate
                termination of your account on Service.
              </li>
              <li>
                You must have the full legal capacity under the Applicable Laws.
                If you do not have full legal capacity, you must not register to
                use our Services. Any person who registers as a user or provides
                his personal information to our Services represents that he has
                full legal capacity under the Applicable Laws.
              </li>
              <li>
                For Business Users: You must be a business or individual using
                Services for business-related and commercial activities only.
                Our Services are intended to enable payments for
                business-related and commercial activities and are not intended
                for personal or person-to-person use. To have an Account, you
                must be doing so in the course of your own business, trade or
                profession without representing any third party to register in
                Salt and not for domestic or household purposes or as a
                Consumer. You hereby acknowledge and represent that at all times
                while using the Services you are using our services for business
                purposes, and that you are not a Consumer for the purpose of the
                application of the Applicable Laws.
              </li>
              <li>
                You warrant that you have the full legal capacity to enter into
                a contract. If you register for the Services on behalf of a
                business or commercial entity, you warrant that you are legally
                authorized under the business entity’s formation documents
                and/or under the laws of your home country to agree to this
                Agreement and you shall supply all such proof to us within three
                (3) days from the date of our request.
              </li>
              <li>
                You must only use the Services through your Account or API
                provided for your own business but not on behalf of any other
                person or entity, except for certain circumstances, in which we
                may require written confirmation from the authorized party
                granting authority to transact on their behalf and any other
                documentation we may require to assist us in order to fulfill
                our compliance policies. We strictly prohibit impersonating or
                falsely claiming an affiliation with any person or entity while
                using our Services. Your failure to observe this Section shall
                be considered to be a material breach of this Agreement and we
                may terminate this Agreement forthwith without compensation.
              </li>
              <li>
                You are responsible for maintaining the confidentiality of your
                account and password, including but not limited to the
                restriction of access to your computer and/or account. You agree
                to accept responsibility for any and all activities or actions
                that occur under your account and/or password, whether your
                password is with our Service or a third-party service. You must
                notify us immediately upon becoming aware of any breach of
                security or unauthorized use of your account.
              </li>
              <li>
                You may not use as a username the name of another person or
                entity or that is not lawfully available for use, a name or
                trademark that is subject to any rights of another person or
                entity other than you, without appropriate authorization. You
                may not use as a username any name that is offensive, vulgar or
                obscene.
              </li>
            </ul>
            <p>
              5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Salt’s Role.</strong>&nbsp;Except for our limited role in
              facilitating certain aspects of payments in accordance with this
              Agreement, we shall in no event be considered to be involved in
              any underlying transactions between&nbsp;&nbsp;you and the Buyer,
              the Platform, suppliers, senders, or the Approved Payee. You
              authorize us to hold, receive, and disburse Funds in accordance
              with your Withdrawal Instructions or Outbound Payment Orders. In
              this limited capacity, we only act as a service provider but we do
              not act as a trustee. We are also neither the Buyer nor the Seller
              of your products or services and are not a party to the underlying
              contract. We may use the services of one or more Group Companies
              and/or Service Providers to provide the Services and process
              Transactions.&nbsp;&nbsp;Notwithstanding the aforesaid, in the
              event that we consider necessary and appropriate at our sole
              discretion, we may request you to provide evidence of the
              underlying transactions.&nbsp;&nbsp;You shall provide all such
              evidence as we reasonably requested within three (3) days from the
              date of our request.
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Salt is
              neither a bank nor a credit institution, and we are not permitted
              to pay interest to you on Funds held in the Payment Account. Our
              Services might offer features or benefits of a bank account in
              partnership with respective Service Providers and Bank. All the
              services that are regulated by the respective regulator in India
              (e.g. Reserve Bank of India) are provided via Service Providers
              having the required license or integrations to provide such
              services being provided.
            </p>
            <p>
              5.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Verification.</strong>&nbsp;Your use of our Services is
              subject to Know Your Customer (“<strong>KYC</strong>”) and
              approval processes. We have the right to request information
              including, but not limited to, information on your identity, your
              business, and the shareholders of the business registering for an
              Account. You agree that we may take means to check and verify
              information you provided, including through the use of third-party
              intermediaries or databases, as necessary to validate your
              identity. Such means may include requests for additional
              information and documents prior to using or during the use of our
              Services, including, but not limited to, documents allowing us to
              verify the identity of you or your entity’s shareholders, proof of
              address or ownership, and additional business-related information
              or documents. You agree to provide to us within three (3) days any
              information required by us for the purpose of complying with its
              compliance obligations. We shall be entitled to deny your use or
              your continued use of the Services if you fail/refuse to provide
              the information requested by us under this Section. We do not, and
              may in no situation be deemed to provide any tax or legal advice
              or approval or counsel with respect to Outbound Payment, their
              amount and timing, nor with respect to your relationship with a
              Payee nor an European Union Tax Administration nor your tax, legal
              or business status nor situation.
            </p>
            <p>
              5.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Account Credentials.</strong>&nbsp;For certain approved
              Platforms, you may request Account Credentials, which you will
              provide to such Platforms from which you wish to receive payment
              through our Services. Your Account Credentials shall be unique to
              you, and shall be provided to the Platform by you in order to
              properly receive and transfer Funds on your behalf.&nbsp;&nbsp;You
              shall strictly keep the Account Credentials safe and confidential.
              You must keep your Credentials secure and you must not disclose
              your Credentials to any third party.&nbsp;&nbsp;You shall be
              solely responsible for all activities conducted by any party using
              or accessing your Account whether such use or access is authorized
              or not and you shall further indemnify us for all losses of us in
              connection therewith.
            </p>
            <p>
              5.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Account History.</strong>&nbsp;We may make summaries of
              your Account activity available to you through the online Account
              portal.
            </p>
            <p>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information
              regarding settlements of Inbound Payment, Fees, and foreign
              exchange rates are displayed in the online transactions history in
              your Account. This constitutes a request for confirmation by us to
              you. You shall proceed to a check over the transaction information
              and notify us of any error or reclamation immediately. Your
              failure to notify us within one (1) month from the day when the
              transaction statement was made available to you shall constitute
              an acceptance by you of the transaction listed in your Account.
            </p>
            <p>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Except
              as required by the Applicable Laws, you shall solely be
              responsible for (a) compiling and retaining permanent records of
              all transactions and other data associated with your Account and
              your use of the Services, and (b) checking all payment activity to
              and from your Account to ensure no unauthorized behavior has
              occurred. Except as required by the Applicable Laws, upon the
              termination of this Agreement for any reason, we shall in no event
              be under any obligation to store, retain, report, or otherwise
              provide any copies of, or access to, any records, documentation or
              other information in connection with your Account or any
              transactions submitted by you through the Services.
            </p>
            <p>
              5.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Processing and Rejections</strong>. We will process a
              Transaction that we deem to be properly sourced and authorized.
              You acknowledge and accept that we &nbsp;may, at any time, reject
              or limit payments at our sole discretion, or suspend access to the
              Account, for reasons related to the requirements of any Applicable
              Laws, instructions by regulator,&nbsp;internal compliance and risk
              management policies of Salt and service providers. You are
              informed that a Transaction carried out within the framework of
              this Agreement may be subject to the national financial
              intelligence unit’s right to disclosure. You may, to the extent
              permitted by the Applicable Laws, access all of the information
              disclosed, provided this right to access does not undermine the
              purpose of the fight against money laundering or terrorist
              financing, where this data concerns you. No prosecutions or civil
              liability actions can be brought or any professional sanction
              taken against Salt, its managers or employees who have reported
              their suspicions in good faith to the relevant authorities.
            </p>
            <p>
              5.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>&nbsp;Restricted Use.</strong>&nbsp;You agree that in
              connection with your use of our Services, you will not:
            </p>
            <ol type="a">
              <li>
                commit any breach of this Agreement, Privacy Policies and any
                other agreements that apply to you;
              </li>
              <li>
                violate any Applicable Law and any laws or regulations of the
                country where you manufacture, purchase, store or sell your
                products or provide services;
              </li>
              <li>engaged in unlawful businesses or activities;</li>
              <li>
                infringe or misappropriate the intellectual property rights or
                rights of publicity or privacy of Salt or any third party;
              </li>
              <li>Sell counterfeit goods;</li>
              <li>Provide false, inaccurate or misleading information;</li>
              <li>
                Refuse to cooperate in an investigation or provide confirmation
                of your identity or any information you provide to us; or
              </li>
              <li>
                Send or receive Funds that Salt and/or Service Providers
                reasonably believes to be potentially involve in money
                laundering, terrorist financing or other illegal activities.
              </li>
            </ol>
            <p>
              You may use Service only for lawful purposes and in accordance
              with Terms. You agree not to use Service:
            </p>
            <ol type="a">
              <li>
                In any way that violates any applicable national or
                international law or regulation.
              </li>
              <li>
                For the purpose of exploiting, harming, or attempting to exploit
                or harm minors in any way by exposing them to inappropriate
                content or otherwise.
              </li>
              <li>
                To transmit, or procure the sending of, any advertising or
                promotional material, including any “junk mail”, “chain letter,”
                “spam,” or any other similar solicitation.
              </li>
              <li>
                To impersonate or attempt to impersonate Company, a Company
                employee, another user, or any other person or entity.
              </li>
              <li>
                In any way that infringes upon the rights of others, or in any
                way is illegal, threatening, fraudulent, or harmful, or in
                connection with any unlawful, illegal, fraudulent, or harmful
                purpose or activity.
              </li>
              <li>
                To engage in any other conduct that restricts or inhibits
                anyone’s use or enjoyment of Service, or which, as determined by
                us, may harm or offend Company or users of Service or expose
                them to liability.
              </li>
            </ol>
            <p>Additionally, you agree not to:</p>
            <ol type="a">
              <li>
                Use Service in any manner that could disable, overburden,
                damage, or impair Service or interfere with any other party’s
                use of Service, including their ability to engage in real time
                activities through Service.
              </li>
              <li>
                Use any robot, spider, or other automatic device, process, or
                means to access Service for any purpose, including monitoring or
                copying any of the material on Service.
              </li>
              <li>
                Use any manual process to monitor or copy any of the material on
                Service or for any other unauthorized purpose without our prior
                written consent.
              </li>
              <li>
                Use any device, software, or routine that interferes with the
                proper working of Service.
              </li>
              <li>
                Introduce any viruses, trojan horses, worms, logic bombs, or
                other material which is malicious or technologically harmful.
              </li>
              <li>
                Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of Service, the server on which Service is
                stored, or any server, computer, or database connected to
                Service.
              </li>
              <li>
                Attack Service via a denial-of-service attack or a distributed
                denial-of-service attack.
              </li>
              <li>
                Take any action that may damage or falsify Company rating.
              </li>
              <li>
                Otherwise attempt to interfere with the proper working of
                Service.
              </li>
            </ol>
            <p>
              If Salt and/or Service Providers believes that you may have
              engaged in any of the above Restricted Use, at our sole
              discretion, we may suspend or terminate your Account, or refuse to
              provide you any Services in the future. You shall further
              indemnify us for all losses of us in connection therewith.
            </p>
            <p>
              5.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Court Orders or Regulatory Actions</strong>. If Salt is
              notified of a court order, regulatory action or equivalent legal
              or governmental process, that affects your Account, we may be
              required to temporarily or permanently withheld, freeze all or
              some portion of the money in your Account, or even release it to
              third parties. Salt will decide, at our sole discretion, what kind
              of action is required in such circumstances. Unless the court
              order, the Applicable Laws, regulatory requirement or other legal
              process requires otherwise, we will notify you of these actions to
              the extent permitted by the Applicable Laws. Salt shall in no
              event under any obligation to contest or appeal any court order or
              legal process involving you or your Account.
            </p>
            <p>
              5.10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Compliance of Sanctioned Regulations.&nbsp;</strong>You
              understand that Salt shall comply with all the applicable sanction
              laws and regulations (“<strong>Sanction Regulations</strong>”)
              enforced by various jurisdiction which shall include, without
              limitation, India, the United States of America. No Service shall
              be provided to any individual or entity which (a) prescribed as
              sanctioned; (b) subject to any sanctions; (c) violates any
              Sanction Regulations; and/or (d) violates the internal compliance
              and risk management policies of Salt. Therefore, you shall
              strictly comply and be bound by the Sanction Regulations and
              policies of Salt and shall not use any service to engage in any
              activity which violates or tends to violate the Sanctioned
              Regulations, which shall include, without limitation, using
              Services to process Funds originating from or shipping to any
              persons, countries, organizations and/or other entities prescribed
              as sanctioned or subject to any sanctions under the Sanction
              Regulations. Otherwise Salt shall be entitled to suspend or
              terminate all or part of Services without compensation to you, and
              you should indemnify Salt from all losses arising therefrom (if
              any).
            </p>
            <p>
              6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Inbound Payment</strong>
            </p>
            <p>
              6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Only Platforms
              or senders approved by us may make payments to your Account for
              the purposes permitted under the Services. We may approve such
              Platforms or senders at its sole discretion. Inbound Payment that
              are not approved by us may be rejected. Any costs and expenses
              associated with rejected payments shall be borne by you, as
              applicable. You represent that the acceptance of Funds through the
              Services shall in no event commit any breach of your Agreement
              with the Platform.
            </p>
            <p>
              6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Salt shall
              allow Funds be received in U.S. Dollars, Canadian Dollars, Euros,
              British Pounds, Japanese Yen, Singapore Dollars, Australian
              Dollars, Mexico Peso, UAE Dirham. We may, at any time at its sole
              discretion, enable or disable any currencies. Payment Accounts
              denominated in these currencies are held with banks established
              either in the country of origin of the currency or in another
              country.&nbsp;
            </p>
            <p>
              6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You may provide
              your Account Credentials to the Platforms from whom you wish to
              receive payment through the Services. You acknowledge and confirm
              that the Services are not a bank account and you are not permitted
              to carry out any activity that falls outside of the scope of the
              our Services. You agree not to conduct any direct communication
              with any bank or Service Provider that is involved in provision of
              the Services unless such communication is approved by us in
              writing.&nbsp;&nbsp;Any direct contact with a bank or other
              payment provider regarding the Services may result in immediate
              termination of your use of the Services.
            </p>
            <p>
              6.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You acknowledge
              and confirm that the time required for Inbound Payment to reach
              your Payment Account shall in no event be under Salt’s control. As
              an illustration only but not otherwise, payments shall typically
              arrive at your Payment Account within one (1) to three (3)
              Business Days from the date of remittance but there is no
              guarantee. If an Inbound Payment is not received within five (5)
              Business Days from the payment instruction of the relevant
              Platform, we may initiate an internal investigation on your behalf
              upon your request. You shall directly resolve any issues or
              disputes associated with Platforms and their policies with the
              Platform. Salt shall in no event be under any obligation to
              resolve such issues or disputes for you.
            </p>
            <p>
              6.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Salt shall use
              commercially reasonable efforts to inform you of Inbound Payment
              received to a Payment Account by updating your Balance within one
              (1) Business Day from the date of receipt of the Funds in the
              Payment Account.
            </p>
            <p>
              6.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If requested by
              Platform or Service Provider, or if Salt deems it necessary at its
              sole discretion, Salt shall provide a receipt in the name of you,
              evidencing Salt’s receipt of Funds as instructed by you. You
              authorize us to provide a copy of this Agreement as well as
              Inbound Payment, Outbound Payment information to any Platform or
              Service Provider that requests information concerning the
              Merchant, Inbound Payment or Withdrawal Transaction.
            </p>
            <p>
              7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Withdrawing Funds</strong>
            </p>
            <p>
              7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You may receive
              Funds in only your Beneficiary Account approved by us. We may
              refuse to carry out any Withdrawal Instruction to any Beneficiary
              Account that is not under your name, or to a bank account that you
              are not the beneficial holder.
            </p>
            <p>
              7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;After the
              completion of a Platform Transaction and when your Balance is
              positive, you may submit a Withdrawal Instruction to instruct us
              to transfer part or all of the Balance to your Beneficiary
              Account, net of Fees. The Withdrawal Instruction must include the
              following information:
            </p>
            <p>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the
              amount requested in the currency corresponding to the Funds we
              received from the Platform; and
            </p>
            <p>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;valid
              Beneficiary Account details.
            </p>
            <p>
              7.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With respect to
              Beneficiary Account details:
            </p>
            <p>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You
              shall ensure that your Beneficiary Account information is true,
              accurate and complete. We shall in no event be held responsible
              for Funds being sent to the wrong bank account as a result of your
              provision of&nbsp;&nbsp;untrue, inaccurate or incomplete
              Beneficiary Account information. In the event that the payment is
              rejected due to untrue, inaccurate or incomplete Beneficiary
              Account information provided by you, you shall solely be
              responsible for relevant Fees and we may directly deduct such Fees
              from your Balance. In case of any incorrect or misdirected
              payment, we shall take reasonable measures to assist you with
              tracing and, if reasonably feasible, recovering such payments, but
              we shall in no event be liable for any payments that cannot
              be&nbsp;recovered.&nbsp;&nbsp;All our additional costs in relation
              thereto shall be borne by you.
            </p>
            <p>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You
              shall ensure that your Beneficiary Account is in good standing and
              able to receive Funds. If your Beneficiary Account bank rejects
              the Withdrawal Transaction from us, Funds will be held with us
              until a valid Beneficiary Account is provided.&nbsp;&nbsp;All
              costs associated thereto shall be borne by you.
            </p>
            <p>
              (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Salt
              shall be entitled to reject any Beneficiary Accounts that do not
              comply with Salt’s compliance requirements and/or if payments to
              such Beneficiary Account would violate Applicable
              Laws.&nbsp;&nbsp;You shall provide other Beneficiary Account so
              that we can continue to provide the Services to you.
            </p>
            <p>
              7.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Once you submit
              a Withdrawal Instruction to us to execute the Withdrawal
              Transaction and debit the Beneficiary Account with the amount of
              the transfer, and all applicable Fees, the withdrawals to your
              Beneficiary Account shall be irrevocable and you will not be able
              to refund the Funds transferred into your Beneficiary Account.
            </p>
            <p>
              7.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;After receiving
              the Withdrawal Instruction, we will initiate a Withdrawal
              Transaction in the amount and currency you selected, less any
              Fees. Any Fees charged by the bank of your Beneficiary Account
              shall be your sole responsibility and we shall not be liable for
              such fees.
            </p>
            <p>
              7.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We reserves the
              right to offset from any Balance any amounts owed to Salt by you,
              including, but not limited to, all Fees payable under this
              Agreement.
            </p>
            <p>
              7.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Withdrawal
              Instructions received by Salt will be processed within one (1) to
              three (3) Business Days at the currency exchange rate as detailed
              in Section 9.3 below.
            </p>
            <p>
              7.7 &nbsp;&nbsp;&nbsp; We may, at its sole and absolute
              discretion, settle your Balance to your Beneficiary Account if the
              Balance is not withdrawn by you for a period from time to time
              specified by us in accordance with the Applicable Laws or if the
              applicable laws require us to do so.
            </p>
            <p>
              8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Outbound Payment</strong>
            </p>
            <p>
              8.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You shall
              ensure that any Outbound Payment Order be accompanied with the
              following information, if applicable:
            </p>
            <p>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the
              amount and currency under which of the Outbound Payment Order has
              to be executed;
            </p>
            <p>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the
              name of the relevant Payee;
            </p>
            <p>
              (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the
              international bank account number or, where relevant, the payment
              account’s details of the relevant Payee; and
            </p>
            <p>
              (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;any
              other information required by us to proceed with the Outbound
              Payment Order, including, without limitation, the underlying
              transaction details and documents, which will include any
              information necessary for us to comply with the Applicable Laws.
            </p>
            <p>
              8.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Salt may refuse
              to execute an Outbound Payment Order in case of technical issue or
              if the information provided by the Customer is untrue, inaccurate
              or incomplete. An information as to this refusal shall in such
              case be available to the Customer on the Account as long as the
              relevant Outbound Payment Order will be impossible.
            </p>
            <p>
              8.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The time of
              receipt of an Outbound Payment Order, the time of execution and
              the value date are determined as follows:
            </p>
            <p>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Time of receipt. The time of
              receipt of an Outbound Payment Order&nbsp;is the time&nbsp;when
              the&nbsp;Outbound Payment Order&nbsp;is actually received
              by&nbsp;Salt. If&nbsp;the Outbound Payment Order is received by
              Salt after the cut-off time (set by the Salt’s contracting entity)
              on a&nbsp;Business&nbsp;Day&nbsp;or in a day which is not a
              Business Day, the&nbsp;Outbound Payment Order&nbsp;shall be deemed
              to have been received on the immediate
              succeeding&nbsp;Business&nbsp;Day&nbsp;for Salt’s contracting
              entity.&nbsp;Once the&nbsp;Outbound Payment Order is received by
              Salt, it shall be irrevocable.
            </p>
            <p>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;Time of execution.&nbsp;After receipt
              of an Outbound Payment Order, Salt shall ensure that the amount of
              the Outbound Payment is credited to the payee’s bank or payment
              service provider’s account at the latest by the end of the
              Business Day immediately succeeding receipt of the Outbound
              Payment Order.
            </p>
            <p>
              (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Value date.&nbsp;The debit value
              date for the Customer acting as payer shall be no earlier than the
              point in time at which the amount of the Outbound Payment Order is
              debited from the Payment Account.
            </p>
            <p>
              (d)&nbsp;&nbsp;&nbsp;&nbsp;The time limits.&nbsp;The time limits
              prescribed above are usual time limits and apply only subject to
              the Payment Account Balance being positive and showing sufficient
              Funds in order for Salt to proceed with the requested Outbound
              Payment Order.&nbsp;&nbsp;For the sake of clarity, the time may be
              extended due to practical circumstances which we shall inform you
              accordingly.
            </p>
            <p>
              9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Fees</strong>
            </p>
            <p>
              9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Salt shall
              advise you all Fees that may be charged by us against you through
              the Account. Acting reasonably, and following notice to you, we
              may from time to time adjust existing Fees or introduce new Fees.
              You shall pay all Fees as from time to time adjusted by us and
              your continued use of the Salt Services indicates your continued
              acceptance of the Fees. When we adjust our Fees, we will publish
              the updated Fees in your Account portal, website and may send you
              an Email notification (if required). If you have any questions or
              are unclear as to any Fees, you should contact our Customer
              Support.
            </p>
            <p>
              9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fees payable by
              you will be deducted from the Payment Account, and will be charged
              when the Transaction is executed. You hereby authorize and
              instruct us to so deduct such Fees.
            </p>
            <p>
              9.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your
              Transaction may be subject to currency conversions. If the
              Transaction involves a currency conversion, it will be completed
              at the foreign exchange rate at the applicable transaction time as
              determined by us at our sole and absolute discretion. To avoid the
              fluctuation of foreign exchange rate, the foreign exchange rate of
              each Transaction will be determined when it is processed.
              Therefore, subject to the Applicable Laws, the foreign exchange
              rate prior to the Transaction Salt provided to you is for
              reference only and the actual foreign exchange rate during the
              Transaction shall prevail.&nbsp;&nbsp;You acknowledge and confirm
              that you shall be bound by the applicable exchange rate from time
              to time.
            </p>
            <p>
              10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Termination and Suspension</strong>
            </p>
            <p>
              10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you wish to terminate this
              Agreement or your Account, you may simply discontinue using the
              Services. The provisions of this Agreement relating to ownership
              provisions, warranty disclaimers, indemnity and limitations of
              liability shall survive termination of this Agreement.
            </p>
            <p>
              10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We may suspend or terminate your
              access to all or any part of the Services at any time, with or
              without cause. Where possible, we may provide at least seven (7)
              days’ prior notice by email, unless there are exceptional
              circumstances, or regulatory circumstances (including but not
              limited to court or governmental orders) causing us to take
              immediate action, such as where we discover that you have provided
              false, misleading, untrue, incomplete or inaccurate information or
              have otherwise acted dishonestly; (b) you commit a breach of this
              Agreement or any other agreement you enter into with Salt; (c)
              your Account has been compromised or for other security reasons;
              (d) you engage in fraud, money laundering, terrorist ﬁnancing or
              other illegal activities or we reasonably suspect the same; (e)
              you use Services illegally or fraudulently in violation of
              Applicable Law or we reasonably suspect the same; or (f) there are
              other grounds that we considers it appropriate. Together with a
              termination/suspension notice, we may also provide instructions on
              how to withdraw remaining Funds, if it is not forbidden by the
              Applicable Laws.
            </p>
            <p>
              10.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When your Account is suspended
              or terminated, any further attempted use of the Service will
              result in your Funds being rejected and returned to the Platform,
              and may warrant notification to appropriate authorities. You are
              solely responsible for any fees incurred in connection with the
              rejected payments.
            </p>
            <p>
              10.4&nbsp;&nbsp; All provisions of Terms which by their nature
              should survive termination shall survive termination, including,
              without limitation, ownership provisions, warranty disclaimers,
              indemnity and limitations of liability.
            </p>
            <p>
              11&nbsp;&nbsp;&nbsp;&nbsp;<strong>Inactive Accounts</strong>
            </p>
            <p>
              11.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your Account is deemed to be
              inactive if:
            </p>
            <p>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the
              Payment Account has no Transactions during a period of twelve (12)
              consecutive months; and
            </p>
            <p>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The
              Customer to whom the Account is registered, or its legal
              representative or authorized person, is not clearly, in any form
              whatsoever, participating in Services.
            </p>
            <p>
              11.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Three (3) months prior to
              reaching inactive status, we may send you an initial notification
              to your email address on our records to request action, provide
              options for keeping your account active, and inform you of the
              consequences of not responding.&nbsp;
            </p>
            <p>
              11.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you do not respond to our
              initial notice within three (3) months, and your Balance is zero,
              we may automatically close or freeze your Account which blocks all
              Transaction.
            </p>
            <p>
              11.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you do not respond to our
              initial notice within three (3) months, and your Balance is
              positive, we will make reasonable efforts to contact you with a
              final notice, after which your Funds will be considered
              “inactive”. Inactive Account may be subject to our charges as from
              time to time be announced by us, and will continue to be deducted
              until the balance is depleted, at which point your Account will be
              closed.&nbsp;
            </p>
            <p>
              11.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please contact Customer Service
              if you have any questions about remaining Funds in your Account if
              it has been closed pursuant to this section, or if you are the
              legal representative of an incapacitated or deceased Customer.
            </p>
            <p>
              12&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Customer Obligations and Warranties</strong>
            </p>
            <p>
              12.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You represent and warrant that
              you are not acting on behalf of, or for the benefit of, anyone
              else, unless in case of a natural person, opening the account for
              and under the direction of the company or legal person or entity,
              that employs such a natural person.
            </p>
            <p>
              12.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You hereby undertake, until
              termination of this Agreement, to communicate promptly to Salt any
              change in your business ownership, shareholding and directorship,
              business activities, post mail address, email address, phone
              contact details or any modification with respect to your account
              with the Beneficiary Account.
            </p>
            <p>
              12.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You shall provide us with all
              KYC information, business details and documents, transaction data
              and documents relating to all transactions relating to any
              Transaction within three (3) days from the date of our request.
            </p>
            <p>
              12.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the event of a breach of the
              above provisions, you shall be liable to Salt for the resulting
              damages and indemnify us from any claims of third parties.
            </p>
            <p>
              13&nbsp;&nbsp;&nbsp;&nbsp;<strong>Complaints</strong>
            </p>
            <p>
              13.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you feel that we have not met
              your expectations in the delivery of our services or if you think
              we have made a mistake, you can file a complaint. General
              complaints can be made to our customer service center by emailing
              at&nbsp;<a href="mailto:support@salt.pe">support@salt.pe</a>
            </p>
            <p>
              13.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Refer to Section 19 for
              jurisdiction-specific complaint procedures.
            </p>
            <p>
              14&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Security Use of Services</strong>
            </p>
            <p>
              14.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You shall adequately
              protect your Account against any loss, theft, misappropriation or
              unauthorized use of your Account by safeguarding all IDs,
              passwords, and Account Credentials, as well as your computer,
              mobile phone or other device.
            </p>
            <p>
              14.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must contact Customer
              Support (see Section 3.3) immediately if any of following should
              occur:
            </p>
            <p>
              (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You
              become aware of any loss, theft, misappropriation or unauthorized
              use of your Account;
            </p>
            <p>
              (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You
              become aware of any unauthorized or erroneous Withdrawal
              Instruction or Transaction no later than three (3) months after
              the debit/credit date.
            </p>
            <p>
              14.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We will provide appropriate
              means at all times for you to make such notice and shall provide
              you with the means to prove, for eighteen (18) months following
              this notice, that you made such notice.
            </p>
            <p>
              15&nbsp;&nbsp;&nbsp;&nbsp;<strong>Limitation of Liability</strong>
            </p>
            <p>
              15.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In no event will Salt, or its
              Service Providers, be liable with respect to any subject matter of
              this Agreement under any contract, negligence, strict liability or
              other legal or equitable theory for: (i) any special, incidental
              or consequential damages; (ii) the cost of procurement for
              substitute services; (iii) for interruption of use or loss or
              corruption of data; or (iv) for any amounts that exceed the fees
              paid by you to Salt under this Agreement during the twelve (12)
              month period prior to the cause of action. Salt shall have no
              liability for any failure or delay due to matters beyond its
              reasonable control.
            </p>
            <p>
              15.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In respect of any Inbound
              Payment, you hereby release the Platform from liability for such
              payment as of when the Funds related thereto are actually received
              by us and not as of that later time when the Funds are settled to
              you by us.
            </p>
            <p>
              15.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outbound Payment Orders can only
              be executed if they comply with regulatory standards. We will not
              be held liable for any damages which could result from the
              non-execution or defective execution of an Outbound Payment Order
              where such non-execution or defective execution is due to the fact
              that the Customer provided information which was untrue,
              inaccurate, incomplete or incorrect. In the case of paying VAT in
              European Union, Outbound Payment Orders shall be executed
              according to the relevant Approved Payee’s international bank
              account number (IBAN) provided that such bank account is held with
              a bank in the European Union (the “
              <strong>Unique Identifier</strong>”). In the case of a discrepancy
              between the Unique Identifier provided by the Customer and any
              other information, we may, without incurring any liability, rely
              solely on the Unique Identifier. In such case, the Funds under the
              relevant Outbound Payment Order transmitted by the Customer will
              be deemed to have been transferred to the intended beneficiary. If
              the Unique Identifier provided by the Customer is incorrect, we
              will not be held liable for any damages which could result from
              the non-execution or defective execution of the Outbound Payment
              Order so long as Salt has executed such Outbound Payment Order in
              accordance with the indicated Unique Identifier. The Customer will
              assume sole responsibility thereto and reimburse all costs of
              Salt.
            </p>
            <p>
              15.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You acknowledge that Salt may
              provide the Services using facilities provided by banks, payment
              service providers, clearing networks and other third-party payment
              processing services. No commercial agreement exists between the
              Service Providers and yourself and each of the Service Providers
              will accordingly have no direct liability to you.
            </p>
            <p>
              15.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We shall not be liable for any
              underlying sales transactions occurring on the Platform between
              either you or a Buyer. It is your responsibility to ensure that
              you only make payments to or receive payments from persons or
              entities related to commercial transactions in compliance with
              your applicable legal obligations. Salt has no influence on the
              underlying process of buying or selling goods, and no legal
              relationship with the Platform or Buyers, and will not be made
              liable to or in respect of any Buyer or Platform or for the
              underlying products and/or services being sold or bought, nor for
              the correct completion of any sale or purchase of goods or
              services. You assume exclusive responsibility for your product and
              your obligations to Buyers and Platforms, and shall indemnify and
              hold Salt harmless from any claim by any of them against Salt. The
              sale of counterfeit goods or goods breaching intellectual property
              rights can also lead to legal action by rights holders and a
              potential loss of Funds. If you are in doubt as to the legality of
              a transaction you should not continue with your payment.
            </p>
            <p>
              15.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You shall solely be responsible
              for reporting to all applicable government tax authorities all
              Funds underlying your use of the Services and for the payment of
              any applicable taxes that apply to such payments as well as any
              other applicable reporting requirements including, but not limited
              to, any customs or foreign currency controls.&nbsp;&nbsp;We may
              request you to provide proof of payment of taxes as and when it
              considers necessary and appropriate.&nbsp;&nbsp;
            </p>
            <p>
              15.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Neither party shall be held
              liable or considered to have failed under these rules in case of
              late or non-performance when their cause is related to a force
              majeure situation as defined by the governing law defined in
              Section 3.
            </p>
            <p>
              15.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The maximum liability
              of&nbsp;Salt&nbsp;under this Agreement (including any and all
              claims for breach of this Agreement) for any single event shall
              not exceed 5% of the Fees collected by Salt from you for the 12
              months immediately before such event. The aggregate maximum
              liability of&nbsp;Salt&nbsp;under this Agreement (including any
              and all claims for breach of this Agreement) for all events shall
              not exceed 105% of the Fees collected by Salt from you for the 12
              months immediately before such events.
            </p>
            <p>
              15.9&nbsp;&nbsp;&nbsp;&nbsp; EXCEPT AS PROHIBITED BY LAW, YOU WILL
              HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS
              HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR
              CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS’ FEES
              AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION,
              OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR
              ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT,
              NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
              CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY
              CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS
              AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL
              LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN
              PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS
              PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF
              COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS
              AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
              CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
              EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
              DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO
              YOU.
            </p>
            <p>
              15.10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All of Section 15 of this
              Agreement shall survive termination&nbsp;hereof.
            </p>
            <p>
              16<strong>&nbsp;&nbsp;&nbsp;&nbsp;Protection of Your Data</strong>
            </p>
            <p>
              16.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We will ensure that all your
              personal information is held in accordance with the data privacy
              and security provisions of Applicable Law and our published
              Privacy Policy. Your use of the Services is subject to the Privacy
              Policy which you agree to as part of these Terms and Conditions.
              Please make yourself familiar with the Privacy Policy by visiting
              it on our website. Salt in its sole discretion may amend the
              Privacy Policy by an update on the Privacy Policy page of the
              website. You may review the Privacy Policy at any time by clicking
              on the Privacy Policy on the website.
            </p>
            <p>
              16.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;YOU ACKNOWLEDGE AND AGREE THAT
              SALT RESERVES THE RIGHT TO ACCESS AND DISCLOSE PERSONAL DATA
              RELATING TO YOU TO THRID PARTIES TO COMPLY WITH ALL APPLICABLE
              LAWS AND LAWFUL REQUESTS FROM GOVERNMENT AND/OR OTHER REGULATORY
              AUTHORITIES, AND/OR TO PROVIDE SERVICES OF SALT OR PROTECT SALT,
              ITS CUSTOMERS AND OTHER USERS.
            </p>
            <p>
              17&nbsp;&nbsp;&nbsp;&nbsp;<strong>Miscellaneous</strong>
            </p>
            <p>
              17.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Availability of Agreement.</strong>&nbsp;The terms of this
              Agreement have been made available to you prior to its
              commencement and remains available thereafter on our Website. At
              any time during the contractual relationship, you have a right to
              receive, on request, the terms of this Agreement on a durable
              medium in the languages specified in Section 3.2.
            </p>
            <p>
              17.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Changes to Agreement.</strong>&nbsp;We are constantly
              updating the Services, and that means sometimes we have to change
              the terms of this Agreement. Salt, at its sole discretion, may
              amend, revise or update this Agreement. Subject to the
              Jurisdiction Specific Terms, the changes will come into effect
              immediately. If we make any material changes, we shall notify you
              via email or other means. If you disagree with our changes, then
              you should stop using the Services. Your continued use of our
              Services will be subject to the new terms. However, any
              transaction or dispute that arose before the changes shall be
              governed by the Agreement that was in place when the dispute
              arose.
            </p>
            <p>
              17.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Service Providers.</strong>&nbsp;Salt has the right to
              involve third parties in providing the Services. We may require
              that certain processing steps are carried out directly through
              such third parties, completely or partially, as described in the
              Privacy Policy.
            </p>
            <p>
              17.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>
                Prevention of Money Laundering and Terrorist Financing
              </strong>
              . Salt might be subject to Applicable Law regarding the fight
              against money laundering and the terrorist financing. As a result,
              Salt reserves the right to obtain information from you, including,
              but not limited to, information about your identity, business
              transactions, business relationships, and/or financial
              information. In addition, Salt must take all the steps necessary
              to identify you and, where appropriate, the beneficial owner of
              the Account and/or any Inbound Payment linked to the Account. You
              acknowledge that Salt may terminate or postpone, at any time, the
              use of login details, access to an Account or execution of a
              transaction in the absence of any sufficient information about its
              purpose or nature.
            </p>
            <p>
              17.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Severability</strong>.
              Should one or several provisions of these general terms and
              conditions be or become invalid or prove to be unenforceable, this
              shall not affect the validity of the other provisions. In this
              case, the contracting parties shall replace the invalid or
              unenforceable provision by another provision that comes closest to
              the economically intended purpose. The same applies in the case of
              a regulatory gap.
            </p>
            <p>
              17.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Indemnification</strong>
              .&nbsp;You shall indemnify&nbsp;Salt&nbsp;(or its Group Companies
              and their respective directors, officers, employees, agents,
              representatives and authorized persons)(collectively “Indemnified
              Persons”)&nbsp;for and keep&nbsp;the Indemnified
              Persons&nbsp;fully and effectively indemnified against
              (a)&nbsp;all direct and indirect damages (including, without
              limitation, accounting, legal and other professional advisors’
              fees) incurred by&nbsp;the Indemnified Persons&nbsp;on an
              indemnity basis in connection with any breach of the terms and
              conditions herein by you and/or&nbsp;the Indemnified
              Persons’&nbsp;enforcement thereof; or (b)&nbsp;any claim,
              proceeding, damages (including, without limitation, accounting,
              legal and other professional advisors’ fees) that may arise to be
              incurred by&nbsp;the Indemnified Persons&nbsp;in connection with
              the provision of any of the Services, whether or not arising from
              or in connection with your improper use of such&nbsp;the Services
              or any damages to&nbsp;the Indemnified Persons&nbsp;(or&nbsp;their
              respective&nbsp;assets, computer hardware, devices, facilities or
              software) as a result of performing such&nbsp; Services.
            </p>
            <p>
              17.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Disclaimer of&nbsp;Warranties</strong>. The Services shall
              be provided on an “as is” basis and as specifed in Section
              32.&nbsp;&nbsp;Salt and its Service Providers hereby disclaim all
              warranties of any kind, express or implied, including, without
              limitation, the warranties of merchantability, fitness for a
              particular purpose and non-infringement. Neither Salt nor its
              Service Providers, makes any warranty that our Services will be
              error free or that access thereto will be continuous or
              uninterrupted. You understand that you download from, or otherwise
              obtain content or services through, our Services at your own
              discretion and risk.
            </p>
            <p>
              17.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>Governing Law and Jurisdiction</strong>&nbsp;&nbsp;This
              Agreement shall be governed by and construed in accordance with
              the laws of the jurisdiction specified in Section 3.1 with
              reference to the Accounts Jurisdiction (“
              <strong>Governing Law Jurisdiction</strong>”).&nbsp;&nbsp;Subject
              to the Jurisdiction Specific Terms, the parties hereby submit to
              the non-exclusive jurisdiction of the courts of the Governing Law
              Jurisdiction.&nbsp;&nbsp;
              <br />
              Our failure to enforce any right or provision of these Terms will
              not be considered a waiver of those rights. If any provision of
              these Terms is held to be invalid or unenforceable by a court, the
              remaining provisions of these Terms will remain in effect. These
              Terms constitute the entire agreement between us regarding our
              Service and supersede and replace any prior agreements we might
              have had between us regarding Service.
            </p>
            <p>
              18&nbsp;&nbsp;&nbsp;<strong>Terms for OPGSP Services</strong>
            </p>
            <p>
              Salt doesn’t acts as an Online Payment Gateway Service Provider
              (“OPGSP”) (as prescribed by the Reserve Bank of India) (“OPGSP”)
              in India for the purpose of facilitating certain payment services.
              Salt partners with a Service provider licensed and regulated by
              Reserve bank of India as an OPGSP provider. The customer in India
              may be eligible to use these Services to receive payments (but not
              to make outbound) via this Service Provider as OPGSP, as described
              in the foregoing Terms and Conditions, subject to the terms set
              forth by such Service Provider and notified via email, website and
              other channels.
            </p>
            <p>
              You may use the Services for export of such goods and services as
              permitted under the laws of India, including but not limited to
              the prevalent Foreign Trade Policy of India, and in all cases only
              for lawful and valid transactions and not for any prohibited
              transaction as detailed in the Terms and Conditions.
            </p>
            <p>
              When withdrawal, you must indicate the purpose of the transactions
              underlying the Eligible Inbound, as requested under India
              regulations to enable collection of data relating to the
              transactions as foreign exchange transactions.&nbsp;&nbsp;Failure
              to provide Salt with accurate information shall be your sole
              responsibility. We will not be liable for withdrawn funds being
              sent to the wrong bank account as a result of you providing
              incorrect details. Providing inaccurate information regarding your
              bank account may result in rejection of funds and/or blocking your
              use of the Services.
            </p>
            <p>
              Eligible Inbound received by Service Provider on your behalf as
              OPGSP will be maintained in a Nostro collection account of an
              Authorized Dealer Bank in India, until being transferred
              exclusively to your designated bank account subject to the
              applicable law.
            </p>
            <p>
              Notwithstanding the foregoing, you may use Salt Outbound Services
              of VAT to pay your VAT bill to tax authority, and the amount equal
              to the underlying amount of your VAT payment will be redirected to
              the VAT authority, instead of being transferred to your bank
              account.
            </p>
            <p>
              19 &nbsp;&nbsp;<strong>Purchases</strong>
            </p>
            <p>
              If you wish to purchase any product or service made available
              through Service (“Purchase”), you may be asked to supply certain
              information relevant to your Purchase including but not limited
              to, your credit or debit card number, the expiration date of your
              card, your billing address, and your shipping information.
            </p>
            <p>
              You represent and warrant that: (i) you have the legal right to
              use any card(s) or other payment method(s) in connection with any
              Purchase; and that (ii) the information you supply to us is true,
              correct and complete.
            </p>
            <p>
              We may employ the use of third party services for the purpose of
              facilitating payment and the completion of Purchases. By
              submitting your information, you grant us the right to provide the
              information to these third parties subject to our Privacy Policy.
            </p>
            <p>
              We reserve the right to refuse or cancel your order at any time
              for reasons including but not limited to: product or service
              availability, errors in the description or price of the product or
              service, error in your order or other reasons.
            </p>
            <p>
              We reserve the right to refuse or cancel your order if fraud or an
              unauthorized or illegal transaction is suspected.
            </p>
            <p>
              20&nbsp;&nbsp;
              <strong>Contests, Sweepstakes and Promotions</strong>
            </p>
            <p>
              Any contests, sweepstakes or other promotions (collectively,
              “Promotions”) made available through Service may be governed by
              rules that are separate from these Terms of Service. If you
              participate in any Promotions, please review the applicable rules
              as well as our Privacy Policy. If the rules for a Promotion
              conflict with these Terms of Service, Promotion rules will apply.
            </p>
            <p>
              21 &nbsp;<strong>Subscriptions</strong>
            </p>
            <p>
              Some parts of Service are billed on a subscription basis
              (“Subscription(s)”). You will be billed in advance on a recurring
              and periodic basis (“Billing Cycle”). Billing cycles will be set
              depending on the type of subscription plan you select when
              purchasing a Subscription.
            </p>
            <p>
              At the end of each Billing Cycle, your Subscription will
              automatically renew under the exact same conditions unless you
              cancel it or Poziom Ventures Private Limited cancels it. You may
              cancel your Subscription renewal either through your online
              account management page or by contacting support@salt.pe customer
              support team.
            </p>
            <p>
              A valid payment method is required to process the payment for your
              subscription. You shall provide Poziom Ventures Private Limited
              with accurate and complete billing information that may include
              but not limited to full name, address, state, postal or zip code,
              telephone number, and a valid payment method information. By
              submitting such payment information, you automatically authorize
              Poziom Ventures Private Limited to charge all Subscription fees
              incurred through your account to any such payment instruments.
            </p>
            <p>
              Should automatic billing fail to occur for any reason, Poziom
              Ventures Private Limited reserves the right to terminate your
              access to the Service with immediate effect.
            </p>
            <p>
              22 &nbsp;<strong>Free Trial</strong>
            </p>
            <p>
              Poziom Ventures Private Limited may, at its sole discretion, offer
              a Subscription with a free trial for a limited period of time
              (“Free Trial”).
            </p>
            <p>
              You may be required to enter your billing information in order to
              sign up for Free Trial.
            </p>
            <p>
              If you do enter your billing information when signing up for Free
              Trial, you will not be charged by Poziom Ventures Private Limited
              until Free Trial has expired. On the last day of Free Trial
              period, unless you cancelled your Subscription, you will be
              automatically charged the applicable Subscription fees for the
              type of Subscription you have selected.
            </p>
            <p>
              At any time and without notice, Poziom Ventures Private Limited
              reserves the right to (i) modify Terms of Service of Free Trial
              offer, or (ii) cancel such Free Trial offer.
            </p>
            <p>
              23 &nbsp;<strong>Subscription</strong>&nbsp;
              <strong>Fee Changes</strong>
            </p>
            <p>
              Poziom Ventures Private Limited, in its sole discretion and at any
              time, may modify Subscription fees for the Subscriptions. Any
              Subscription fee change will become effective at the end of the
              then-current Billing Cycle.
            </p>
            <p>
              Poziom Ventures Private Limited will provide you with a reasonable
              prior notice of any change in Subscription fees to give you an
              opportunity to terminate your Subscription before such change
              becomes effective.
            </p>
            <p>
              Your continued use of Service after Subscription fee change comes
              into effect constitutes your agreement to pay the modified
              Subscription fee amount.
            </p>
            <p>
              24&nbsp;<strong>Content</strong>
            </p>
            <p>
              Our Service allows you to post, link, store, share and otherwise
              make available certain information, text, graphics, videos, or
              other material (“Content”). You are responsible for Content that
              you post on or through Service, including its legality,
              reliability, and appropriateness.
            </p>
            <p>
              By posting Content on or through Service, You represent and
              warrant that: (i) Content is yours (you own it) and/or you have
              the right to use it and the right to grant us the rights and
              license as provided in these Terms, and (ii) that the posting of
              your Content on or through Service does not violate the privacy
              rights, publicity rights, copyrights, contract rights or any other
              rights of any person or entity. We reserve the right to terminate
              the account of anyone found to be infringing on a copyright.
            </p>
            <p>
              You retain any and all of your rights to any Content you submit,
              post or display on or through Service and you are responsible for
              protecting those rights. We take no responsibility and assume no
              liability for Content you or any third party posts on or through
              Service. However, by posting Content using Service you grant us
              the right and license to use, modify, publicly perform, publicly
              display, reproduce, and distribute such Content on and through
              Service. You agree that this license includes the right for us to
              make your Content available to other users of Service, who may
              also use your Content subject to these Terms.
            </p>
            <p>
              Poziom Ventures Private Limited has the right but not the
              obligation to monitor and edit all Content provided by users.
            </p>
            <p>
              In addition, Content found on or through this Service are the
              property of Poziom Ventures Private Limited or used with
              permission. You may not distribute, modify, transmit, reuse,
              download, repost, copy, or use said Content, whether in whole or
              in part, for commercial purposes or for personal gain, without
              express advance written permission from us.
            </p>
            <p>
              25 &nbsp;<strong>Analytics</strong>
            </p>
            <p>
              We may use third-party Service Providers to monitor and analyse
              the use of our Service.
            </p>
            <p>
              26&nbsp;&nbsp;<strong>No Use By Minors</strong>
            </p>
            <p>
              Service is intended only for access and use by individuals at
              least eighteen (18) years old. By accessing or using Service, you
              warrant and represent that you are at least eighteen (18) years of
              age and with the full authority, right, and capacity to enter into
              this agreement and abide by all of the terms and conditions of
              Terms. If you are not at least eighteen (18) years old, you are
              prohibited from both the access and usage of Service.
            </p>
            <p>
              27&nbsp;&nbsp;&nbsp;<strong>Intellectual Property</strong>
            </p>
            <p>
              Service and its original content (including Content provided by
              users), features and functionality are and will remain the
              exclusive property of Poziom Ventures Private Limited and its
              licensors. Service is protected by copyright, trademark, and other
              laws of and foreign countries. Our trademarks may not be used in
              connection with any product or service without the prior written
              consent of Poziom Ventures Private Limited.
            </p>
            <p>
              28&nbsp;&nbsp;&nbsp;<strong>Copyright Policy</strong>
            </p>
            <p>
              We respect the intellectual property rights of others. It is our
              policy to respond to any claim that Content posted on Service
              infringes on the copyright or other intellectual property rights
              (“Infringement”) of any person or entity.
            </p>
            <p>
              If you are a copyright owner, or authorized on behalf of one, and
              you believe that the copyrighted work has been copied in a way
              that constitutes copyright infringement, please submit your claim
              via email to&nbsp;
              <a href="mailto:support@salt.pe">support@salt.pe</a>, with the
              subject line: “Copyright Infringement” and include in your claim a
              detailed description of the alleged Infringement as detailed
              below, under “DMCA Notice and Procedure for Copyright Infringement
              Claims”
            </p>
            <p>
              You may be held accountable for damages (including costs and
              attorneys’ fees) for misrepresentation or bad-faith claims on the
              infringement of any Content found on and/or through Service on
              your copyright.
            </p>
            <p>
              29&nbsp;&nbsp;&nbsp;
              <strong>
                DMCA Notice and Procedure for Copyright Infringement Claims
              </strong>
            </p>
            <p>
              You may submit a notification pursuant to the Digital Millennium
              Copyright Act (DMCA) by providing our Copyright Agent with the
              following information in writing (see 17 U.S.C 512(c)(3) for
              further detail):
            </p>
            <ol type="a">
              <li>
                an electronic or physical signature of the person authorized to
                act on behalf of the owner of the copyright’s interest;
              </li>
              <li>
                a description of the copyrighted work that you claim has been
                infringed, including the URL (i.e., web page address) of the
                location where the copyrighted work exists or a copy of the
                copyrighted work;
              </li>
              <li>
                identification of the URL or other specific location on Service
                where the material that you claim is infringing is located;
              </li>
              <li>your address, telephone number, and email address;</li>
              <li>
                a statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law;
              </li>
              <li>
                a statement by you, made under penalty of perjury, that the
                above information in your notice is accurate and that you are
                the copyright owner or authorized to act on the copyright
                owner’s behalf.
              </li>
              <li>
                You can contact our Copyright Agent via email at&nbsp;
                <a href="mailto:support@salt.pe">support@salt.pe</a>.
              </li>
            </ol>
            <p>
              30&nbsp;&nbsp;&nbsp;<strong>Error Reporting and Feedback</strong>
            </p>
            <p>
              You may provide us either directly at support@salt.pe or via third
              party sites and tools with information and feedback concerning
              errors, suggestions for improvements, ideas, problems, complaints,
              and other matters related to our Service (“Feedback”). You
              acknowledge and agree that: (i) you shall not retain, acquire or
              assert any intellectual property right or other right, title or
              interest in or to the Feedback; (ii) Company may have development
              ideas similar to the Feedback; (iii) Feedback does not contain
              confidential information or proprietary information from you or
              any third party; and (iv) Company is not under any obligation of
              confidentiality with respect to the Feedback. In the event the
              transfer of the ownership to the Feedback is not possible due to
              applicable mandatory laws, you grant Company and its affiliates an
              exclusive, transferable, irrevocable, free-of-charge,
              sub-licensable, unlimited and perpetual right to use (including
              copy, modify, create derivative works, publish, distribute and
              commercialize) Feedback in any manner and for any purpose.
            </p>
            <p>
              31&nbsp;<strong>Links To Other Web Sites</strong>
            </p>
            <p>
              Our Service may contain links to third party web sites or services
              that are not owned or controlled by Poziom Ventures Private
              Limited.
            </p>
            <p>
              Poziom Ventures Private Limited has no control over, and assumes
              no responsibility for the content, privacy policies, or practices
              of any third party web sites or services. We do not warrant the
              offerings of any of these entities/individuals or their websites.
            </p>
            <p>
              YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR
              LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR
              ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE
              ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY
              SUCH THIRD PARTY WEB SITES OR SERVICES.
            </p>
            <p>
              WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
              POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
            </p>
            <p>
              32&nbsp;&nbsp;&nbsp;<strong>Disclaimer Of Warranty</strong>
            </p>
            <p>
              THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
              AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES
              OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR
              SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED
              THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES,
              THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT
              YOUR SOLE RISK.
            </p>
            <p>
              NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
              WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
              SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
              SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR
              ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE
              SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH
              THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
              UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES
              OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS
              OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR
              EXPECTATIONS.
            </p>
            <p>
              COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
              EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT
              LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
              AND FITNESS FOR PARTICULAR PURPOSE.
            </p>
            <p>
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
            <p>
              33&nbsp; &nbsp;<strong>Acknowledgement</strong>
            </p>
            <p>
              BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
              THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
              THEM.
            </p>
            <p>
              34 &nbsp;<strong>Contact Us</strong>
            </p>
            <p>
              Please send your feedback, comments, requests for technical
              support by email:&nbsp;<strong>support@salt.pe</strong>.
            </p>
            <p>&nbsp;</p>
          </div>
        </Container>
        <Footer />
      </div>
    );
  }
}
