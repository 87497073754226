import * as React from "react"

const Table = (props) => (
  <svg
    width={149}
    height={37}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.793 2.243H.833v2.042H12.793v31.907H14.973V4.285H26.933V2.243H12.793Zm26.223 31.864h-.166v2.085h22.075l-.098-.232L46.233 1.52l-.153-.362-.154.362L31.333 35.96l-.099.232h2.23l.043-.102L46.08 6.505l11.715 27.602H39.016Zm44.538-17.123c-1.268.498-2.83.75-4.695.75H71.222V4.285h7.222c2.003 0 3.66.253 4.974.752 1.328.498 2.313 1.24 2.968 2.222.654.982.988 2.225.988 3.74 0 1.516-.326 2.768-.967 3.766-.64.982-1.587 1.723-2.853 2.22Zm-3.24 17.166h-9.092V19.753h9.323c1.874 0 3.415.328 4.631.974h.002c1.221.634 2.127 1.482 2.725 2.543v.001c.601 1.052.902 2.2.902 3.45 0 1.56-.348 2.89-1.037 3.998v.001c-.674 1.094-1.642 1.943-2.908 2.546-1.25.587-2.763.884-4.546.884Zm6.974-14.377a8.356 8.356 0 0 0-2.754-1.223 7.218 7.218 0 0 0 4.218-3.645c.566-1.134.848-2.407.848-3.815 0-1.511-.257-2.83-.777-3.949a7.128 7.128 0 0 0-2.244-2.763c-.96-.74-2.128-1.28-3.5-1.622-1.37-.343-2.916-.513-4.635-.513H69.019v33.949h11.295c2.097 0 3.945-.365 5.541-1.1 1.612-.736 2.877-1.811 3.788-3.225.913-1.417 1.365-3.151 1.365-5.192 0-1.413-.33-2.718-.99-3.913a8.45 8.45 0 0 0-2.73-2.99Zm32.035 14.544v-.167h-16.992V2.243h-2.227v33.949h19.219v-1.875Zm28.731 0v-.167h-18.771V19.753h16.416V17.69h-16.416V4.285h18.725V2.243H127.08v33.949h20.974v-1.875Z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.333}
    />
    <path
      d="M38.933 34.367h19.03L45.996 6.848 33.27 36.077h-1.868L45.996 2.46 60.59 36.077H38.933v-1.71Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.351 33.797h18.733L45.998 8.305 33.66 36.645h-3.136L46 1l15.475 35.646H38.35v-2.85Zm-5.078 2.279h-1.868L45.998 2.46l14.594 33.616H38.935v-1.71h19.03L45.998 6.848 33.273 36.076Z"
      fill="#fff"
    />
  </svg>
)

export default Table