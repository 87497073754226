import React from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";

import "./index.css";
import SALTLogo from "../Icons/SALT";
import TableBySALT from "../Icons/TableBySALT";

import { SALTLinks } from "../../Data";

const NavigationBar = (props) => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="navigation-bar fixed-top p-4"
        // className={`navigation-bar ${props.fixedBar && `fixed-top`}`}
        // style={(props.fixedBar) ? {opacity: '0.5'} : {}}
        variant="dark"
        style={{ zIndex: "500" }}
      >
        <Container>
          <Navbar.Brand href="/" className="ms-0 ms-md-3" style={{marginRight: '20px'}}>
            {props.table ? <TableBySALT /> : <SALTLogo />}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                href={SALTLinks.careers}
                target={"_blank"}
                rel="noreferrer"
                className="we-are-hiring"
                style={{
                  marginRight: "15px",
                  fontWeight: "500",
                  borderRadius: "4px",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  backgroundColor: "rgba(255, 255, 255, 0.15)",
                  opacity: 1,
                  color: "#fff",
                }}
              >
                We're Hiring! 🎉
              </Nav.Link>
            </Nav>
            <Nav>
              {props.table ? (
                <Nav.Link
                  // href={SALTLinks.tableCTA}
                  className="getearlyaccess-action"
                  target={"_blank"}
                  rel="noreferrer"
                  style={{
                    marginRight: "15px",
                    fontWeight: "500",
                    color: "white",
                  }}
                  onClick={() => {
                    props.setDisplayForm(true);
                  }}
                >
                  Get Early Access
                </Nav.Link>
              ) : (
                <>
                  <Nav.Link
                    href={SALTLinks.table}
                    style={{
                      marginRight: "15px",
                      fontWeight: "500",
                      color: "white",
                    }}
                  >
                    TABLE <sup>beta</sup>
                  </Nav.Link>
                  <Nav.Link
                    href={SALTLinks.blog}
                    style={{ marginRight: "15px", fontWeight: "500" }}
                  >
                    Blogs
                  </Nav.Link>
                  <Nav.Link
                    href={SALTLinks.login}
                    style={{ marginRight: "15px", fontWeight: "500" }}
                  >
                    Login
                  </Nav.Link>
                  <Button
                    className="getstarted-action"
                    href={SALTLinks.getstarted}
                    variant="outline-light"
                  >
                    Get Started
                  </Button>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;
