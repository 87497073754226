import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import "./index.css";

import { FeatureSectionDataModel } from "../../Data";

export default class FeaturesSection extends React.Component {
  render() {
    return (
      <>
        {FeatureSectionDataModel.map((sectionData, idx) => (
          <div className="features-section mx-auto" key={idx}>
            <Container className="p-5">
              {(idx + 1) & 1 ? (
                <Row className="p-md-5">
                  <Col className={"order-2 order-xl-1 mt-5 me-xl-5 ms-xl-5"}>
                    <div className="title">{sectionData.title}</div>
                    <div className="subtitle">{sectionData.subtitle}</div>
                  </Col>
                  <Col className={"order-1 order-xl-2 col-12 col-xl-6"}>
                    <img
                      src={sectionData.imagePath}
                      alt={sectionData.title}
                      className="feature-image"
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="p-md-5">
                  <Col className={"col-12 col-xl-6"}>
                    <img
                      src={sectionData.imagePath}
                      alt={sectionData.title}
                      className="feature-image"
                    />
                  </Col>
                  <Col className="mt-5 me-xl-5 ms-xl-5">
                    <div className="title">{sectionData.title}</div>
                    <div className="subtitle">{sectionData.subtitle}</div>
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        ))}
      </>
    );
  }
}
