import React from "react";
import { Container, Button, Row } from "react-bootstrap";

import "./index.css";

import { SALTLinks, StepsDataModel } from "../../Data";

const buttonStyle = {
  fontFamily: "HK Grotesk",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "400",
  letterSpacing: "0em",
  minHeight: "53px",
  borderRadius: "5px",
  background: "linear-gradient(180deg, #1E5EF3 0%, #134FDA 100%)",
  boxShadow: "1.33333px 1.33333px 6.66667px rgba(30, 94, 243, 0.2)",
};
export default class StepsSection extends React.Component {
  render() {
    return (
      <div className="steps">
        <div className="heading">
          Great banking is <span>barely 3 steps</span> away
        </div>
        <Container
          className="steps-card-container"
          style={{ marginBottom: "100px" }}
        >
          <Row style={{padding: '0 12px'}}>
            {StepsDataModel.map((stepsData, idx) => (
              <div className="col-12 col-md-4 mb-3 d-flex align-items-stretch" key={idx}>
                <div className="steps-card card border-0">
                  <div
                    style={{
                      width: "100%",
                      height: "20rem",
                      padding: "25px",
                      zIndex: "100",
                      background: "url(" + stepsData.imagePath + ")",
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                  <div
                    className="card-body d-flex flex-column"
                    style={{
                      background: "rgba(30, 94, 243, 0.05)",
                      marginTop: "-12em",
                      borderRadius: "26px",
                      backgroundClip: "padding-box",
                      padding: "35px",
                    }}
                  >
                    <div
                      className="mt-auto"
                      style={{
                        paddingTop: "12em",
                      }}
                    >
                      <h4>{stepsData.title}</h4>
                      <p className="mb-4">{stepsData.subtitle}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </Container>
        <div className="text-center mt-5 mb-5" style={{textAlign: 'center'}}>
          <Button
            className="getstarted-action d-inline-flex align-items-center text-center"
            href={SALTLinks.getstarted}
            variant="primary"
            size="lg"
            style={buttonStyle}
          >
            Get Started!
          </Button>
        </div>
        <div className="heading">
          <span>Unburden yourself</span> from the baggage of documents.
        </div>
      </div>
    );
  }
}
