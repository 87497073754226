import React from "react";

import "./index.css";
import SALT from "../Icons/SALT";
import TABLE from "../Icons/Table";
import Facebook from "../../Assets/facebook.svg";
import Twitter from "../../Assets/twitter.svg";
import Linkedin from "../../Assets/linkedin.svg";
import Instagram from "../../Assets/instagram.svg";

import { SALTLinks } from "../../Data";

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="footer-container page-footer font-small pt-4">
        <div className="container footer-content">
          <div className="column1">
            {this.props.table ? (
              <a href={SALTLinks.table} alt="TABLE">
                <TABLE />
              </a>
            ) : (
              <a href="/" alt="SALT">
                <SALT />
              </a>
            )}
          </div>
          <div className="column2">
            We do cross border payments and compliances for Indian SMEs.
            <a href={SALTLinks.mail} target="_blank" rel="noreferrer">
              contact@salt.pe
            </a>
          </div>
          <div className="column3">
            <h5 className="category-header">Add us to your life!</h5>
            <ul className="list-unstyled">
              <li>
                <a href={SALTLinks.facebook} target="_blank" rel="noreferrer">
                  <img src={Facebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a href={SALTLinks.twitter} target="_blank" rel="noreferrer">
                  <img src={Twitter} alt="Twitter" />
                </a>
              </li>
              <li>
                <a href={SALTLinks.linkedin} target="_blank" rel="noreferrer">
                  <img src={Linkedin} alt="Linkedin" />
                </a>
              </li>
              <li>
                <a href={SALTLinks.instagram} target="_blank" rel="noreferrer">
                  <img src={Instagram} alt="Instagram" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-divider container" />
        <div className="footer-copyright text-center py-3">
          Copyright @ Poziom Ventures Pvt. Ltd |{" "}
          <a href={SALTLinks.disclaimer}>Disclaimer</a> |{" "}
          <a href={SALTLinks.privacy}>Privacy Policy</a> |{" "}
          <a href={SALTLinks.tos}>Terms of Service</a>
        </div>
      </footer>
    );
  }
}
