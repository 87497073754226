import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";

import "./index.css";

import { SALTLinks } from "../../../Data";

const buttonStyle = {
  fontFamily: "HK Grotesk",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "400",
  letterSpacing: "0em",
  minHeight: "53px",
  borderRadius: "5px",
  background: "linear-gradient(180deg, #1E5EF3 0%, #134FDA 100%)",
  boxShadow: "1.33333px 1.33333px 6.66667px rgba(30, 94, 243, 0.2)",
};

const TableSection3 = ({ displayForm, setDisplayForm }) => {
  return (
    <>
      <div className="table-section3 d-flex">
        <Container>
          <Row>
            <Col className="col-12 mt-5 mb-5 mt-lg-0 mb-lg-0">
              <div className="table-section3-title">
                Good things come to those who wait
                <br />
                but better things come to those who do not.
              </div>
              <div className="table-section3-subtitle">
                Be an Early Adopter!
              </div>
              <div
                className="text-center mt-5 mb-5"
                style={{ textAlign: "center" }}
              >
                <Button
                  className="getearlyaccess-action d-inline-flex align-items-center text-center"
                  // href={SALTLinks.tableCTA}
                  target={"_blank"}
                  rel="noreferrer"
                  variant="primary"
                  size="lg"
                  style={buttonStyle}
                  onClick={() => {
                    setDisplayForm(true);
                  }}
                >
                  Get Early Access
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TableSection3;
