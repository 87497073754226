import React from "react";

import Header from "../../Components/Header";
import Features from "../../Components/Features";
import Footer from "../../Components/Footer";
import FeaturesSection from "../../Components/FeaturesSection";
import HighlightsSection from '../../Components/HighlightsSection';
import StepsSection from "../../Components/StepsSection";
import NewsletterSection from "../../Components/NewsletterSection";
import BlogSection from "../../Components/BlogSection";

export default class HomePage extends React.Component {
  render() {
    return (
      <div>
          <Header/>
          <Features/>
          <HighlightsSection/>
          <FeaturesSection/>
          <StepsSection/>
          <NewsletterSection/>
          <BlogSection/>
          <Footer/>
      </div>
    );
  }
}