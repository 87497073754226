import React from "react";
import { Container, Button, Row, Col } from "react-bootstrap";

import "./index.css";
import NavigationBar from "../../NavigationBar";
import TableHero from "../../../Assets/table-hero.svg";

import { SALTLinks } from "../../../Data";

const buttonStyle = {
  fontFamily: "HK Grotesk",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "400",
  letterSpacing: "0em",
  minHeight: "53px",
  borderRadius: "5px",
  background: "linear-gradient(180deg, #1E5EF3 0%, #134FDA 100%)",
  boxShadow: "1.33333px 1.33333px 6.66667px rgba(30, 94, 243, 0.2)",
};

const Header = ({ displayForm, setDisplayForm }) => {
  return (
    <header className="table-header">
      <NavigationBar table={true} setDisplayForm={setDisplayForm} />
      <Row className="table-header-container">
        <Col className="order-2 order-lg-1 d-flex justify-content-lg-end p-4">
          <div>
            <h1 className="table-title">Fundraise on autopilot 🚀</h1>
            <h6 className="table-subtitle" style={{ maxWidth: "600px" }}>
              One-stop tool for fund management and compliances for start-ups
              raising foreign capital.
            </h6>
            <Button
              className="getearlyaccess-action d-inline-flex align-items-center text-center"
              // href={SALTLinks.tableCTA}
              target={"_blank"}
              rel="noreferrer"
              variant="primary"
              size="lg"
              style={buttonStyle}
              onClick={() => {
                setDisplayForm(true);
              }}
            >
              Get Early Access
            </Button>
          </div>
        </Col>
        <Col
          className="order-1 order-lg-2 col-12 col-lg-6 d-flex justify-content-end"
          style={{ paddingRight: 0 }}
        >
          <img
            src={TableHero}
            alt="TABLE by SALT"
            className="img-fluid d-none d-lg-block"
          />
        </Col>
      </Row>
    </header>
  );
};

export default Header;
