import ManageAccounts from "../Assets/manage-accounts.svg";
import QualityFeatures from "../Assets/quality-features.png";
import Reports from "../Assets/reports.svg";
import Payout from "../Assets/payout.svg";
import Step1 from "../Assets/step1.svg";
import Step2 from "../Assets/step2.svg";
import Step3 from "../Assets/step3.svg";

export const SALTLinks = {
  facebook: "https://www.facebook.com/saltpebank/",
  linkedin: "https://www.linkedin.com/company/saltpe/",
  twitter: "https://twitter.com/saltpe_",
  instagram: "https://www.instagram.com/salt.pe/",
  disclaimer: "/disclaimer",
  tos: "/tos",
  privacy: "/privacy",
  getstarted: "https://app.salt.pe/signup",
  table: "/table",
  tableCTA:
    "https://docs.google.com/forms/d/e/1FAIpQLScbmARJQuaXzzX1ovNntEGb_a8wgQiRQOjpskXcWnRB14_Nfg/viewform",
  login: "https://app.salt.pe/",
  blog: "/blog",
  contactus: "/",
  about: "/",
  careers: "https://saltpe.notion.site/saltpe/Career-a99a713a94614d9db84558347364ad9a",
  mail: "mailto:contact@salt.pe",
  newsletter:
    "https://salt.us17.list-manage.com/subscribe/post?u=b98f2b8119ec8597b36ffd001&amp;id=ab327b8e12",
};

export const FeaturesDataModel = [
  {
    title: "Inward Remittance",
    subtitle: "Don’t lose money for every dollar you earn.",
    tag: "",
  },
  {
    title: "Multi-currency business accounts",
    subtitle:
      "Manage your global business with the convenience of local accounts.",
    tag: "",
  },
  {
    title: "Outward Remittance",
    subtitle: "Save more money on your every spend.",
    tag: "Coming Soon...",
  },
];

export const FeatureSectionDataModel = [
  {
    title: "Manage multiple accounts",
    subtitle:
      "Salt provides you with one of a kind system and experience stitched together for businesses to save their time and resources.",
    imagePath: ManageAccounts,
  },
  {
    title: "Get quality features under one roof",
    subtitle:
      "We want finance to be a seamless experience for everyone. So we will take care of all business jargon and complex codes for you while you relax.",
    imagePath: QualityFeatures,
  },
  {
    title: "Get reports on company finances",
    subtitle:
      "Salt regularly provides clients with deep-dived reports on financial spent and flags out faulty and suspicious transactions.",
    imagePath: Reports,
  },
  {
    title: "Manage collection & payout",
    subtitle:
      "No need to have sleepless nights on month ends anymore. Manage expenses, collections, and payouts on the go around the world in real-time.",
    imagePath: Payout,
  },
];

export const StepsDataModel = [
  {
    title: "REGISTER",
    subtitle: "Sign up with SALT and share the necessary information.",
    imagePath: Step1,
  },
  {
    title: "WRAP UP KYC",
    subtitle: "Share a few more details to finish off the final step.",
    imagePath: Step2,
  },
  {
    title: "BANKING ON THE GO",
    subtitle: "Go in, go hard and enjoy the most relaxed banking experience.",
    imagePath: Step3,
  },
];
