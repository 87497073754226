import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import ShieldCheck from "../../Icons/ShieldCheck";
import BulletArrow from "../../../Assets/BulletArrow.svg";

import "./index.css";

export default class TableSection2 extends React.Component {
  render() {
    return (
      <>
        <div className="table-section2">
          <Container>
            <Row>
              <Col className="col-12 col-lg-6 d-flex justify-content-center">
                <ul
                  className="custom-arrow-bullets"
                  style={{ listStyleImage: `url(${BulletArrow})` }}
                >
                  <li>Open Capital Bank A/c in Priority</li>
                  <li>Receive Money from Your Foreign Investors</li>
                  <li>Unburden the Hassles of ROC & RBI Filings</li>
                </ul>
              </Col>
              <Col className="col-12 col-lg-6 d-flex justify-content-center">
                <ul
                  className="custom-arrow-bullets"
                  style={{ listStyleImage: `url(${BulletArrow})` }}
                >
                  <li>Get Valuation Certificate</li>
                  <li>Save Costs on Forex and Legal Charges</li>
                  <li>Track and Manage Your Fundraise</li>
                </ul>
              </Col>
              <Col className="col-12 mt-5 mb-5 mt-lg-0 mb-lg-0">
                <div className="highlights-bullet">
                  <span>
                    <ShieldCheck />
                  </span>
                  We are guided by an army of industry experts - CA / CS and
                  Valuation Specialists.
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
