import React from "react";
import { Routes, Route } from "react-router-dom";

import HomePage from "../Pages/Home";
import Disclaimer from "../Pages/Disclaimer";
import Privacy from "../Pages/Privacy";
import TOS from "../Pages/TOS";
import Table from "../Pages/Table";
import Chair from "../Pages/Chair";
import NotFound from "../Pages/404";
import TableWaitlist from "../Pages/TableWaitlist";

export default class SRoutes extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/table" exact element={<Table />} />
        <Route path="/disclaimer" exact element={<Disclaimer />} />
        <Route path="/privacy" exact element={<Privacy />} />
        <Route path="/tos" exact element={<TOS />} />
        <Route path="/chair" exact element={<Chair />} />
        {/* do not deploy responses page in prod */}
        {/* <Route path="/responses/token/ceb20772e0c9d240c75eb26b0e37abee" exact element={<TableWaitlist/>} /> */}
        <Route path="*" exact element={<NotFound />} />
      </Routes>
    );
  }
}
