import { BrowserRouter } from "react-router-dom";
import SRoutes from "./Routes/Routes";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4Ps_hU94lJuZl5_DuLKRKHijlnx6RZjI",
  authDomain: "salt-pe.firebaseapp.com",
  projectId: "salt-pe",
  storageBucket: "salt-pe.appspot.com",
  messagingSenderId: "591314812002",
  appId: "1:591314812002:web:5274aedfd80df8e972e53b",
  measurementId: "G-R2NZL3S092"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);


function App() {
  return (
    <BrowserRouter>
        <SRoutes />
      </BrowserRouter>
  );
}

export default App;
