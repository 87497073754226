import React from "react";
import { Col, Row, Button } from "react-bootstrap";

import "./index.css";
import Footer from "../../Components/Footer";
import NavigationBar from "../../Components/NavigationBar";

import Image404 from "../../Assets/404.svg";

const buttonStyle = {
  fontFamily: "HK Grotesk",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "400",
  letterSpacing: "0em",
  minHeight: "53px",
  borderRadius: "5px",
  background: "linear-gradient(180deg, #1E5EF3 0%, #134FDA 100%)",
  boxShadow: "1.33333px 1.33333px 6.66667px rgba(30, 94, 243, 0.2)",
  justifyContent: 'center',
  marginTop: '30px'
};

export default class NotFound extends React.Component {
  render() {
    return (
      <div className="notfound-page">
        {/* <NavigationBar /> */}
        <div className="d-flex justify-content-center">
          <img
            src={Image404}
            alt="404, Page not found"
            className="img-fluid"
            width="700px"
          />
        </div>

        <div className="notfound-section">
          <Row>
            <Col className="col-12 mt-5 mb-5 mt-lg-0 mb-lg-0" style={{display: 'flex', flexDirection: 'column'}}>
              <div className="notfound-title">Oops! Page not found</div>
              <div className="notfound-subtitle">
                We know we are a borderless bank. but seems like these are
                beyond our borders as well : (
              </div>
              <Button
                className="d-inline-flex align-items-center align-self-center text-center"
                href="/"
                variant="primary"
                size="lg"
                style={buttonStyle}
              >
                Back to Home Page
              </Button>
            </Col>
          </Row>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
