import React from "react";
import { Container, Row } from "react-bootstrap";
import Moment from "react-moment";

import "./index.css";

export default class BlogSection extends React.Component {
  constructor() {
    super();
    this.state = { blogDataModel: [], showBlogPosts: 3, isLoading: true };
  }

  getBlogPosts() {
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fblog.salt.pe%2Frss.xml"
    )
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        this.setState({
          blogDataModel: data.items,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ errorMessage: error.toString() });
        console.error("There was an error!", error);
      });
  }

  componentWillMount() {
    this.getBlogPosts();
  }
  render() {
    if (this.state.isLoading) return null;
    return (
      <div className="blog">
        <Container className="blog-card-container">
          <h2>Latest Blogs</h2>
          <Row>
            {this.state.blogDataModel
              .slice(0, this.state.showBlogPosts)
              .map((blogData, idx) => (
                <div className="col-lg-4 mb-3 d-flex align-items-stretch" key={idx}>
                  <div
                    className="card blog-card"
                    onClick={() => window.open(blogData.link, "_blank")}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "250px",
                        padding: "25px",
                        background: "url(" + blogData.thumbnail + ")",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                    <div
                      className="card-body d-flex flex-column"
                      style={{ padding: "35px" }}
                    >
                      <h4 className="blog-card-title">{blogData.title}</h4>
                      <p className="blog-card-desc mb-4">
                        {(blogData.description.replace(/<[^>]+>/g, "")).split(' ').slice(0, 25).join(' ')}...
                      </p>
                      <div className="mt-auto">
                        <div className="blog-divider" />
                        <h6 className="blog-card-date align-self-center text-center">
                          <Moment
                            format="MMMM D, YYYY"
                            date={blogData.pubDate}
                          />
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Row>
        </Container>
      </div>
    );
  }
}
