import React from "react";
import { Container, Button, Row, Col } from "react-bootstrap";

import "./index.css";
import NavigationBar from "../NavigationBar";
import WorldMap from "../../Assets/world-map.svg";

import { SALTLinks } from "../../Data";

const buttonStyle = {
  fontFamily: 'HK Grotesk',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: '400',
  letterSpacing: '0em',
  minHeight: "53px",
  borderRadius: "5px",
  background: "linear-gradient(180deg, #1E5EF3 0%, #134FDA 100%)",
  boxShadow: "1.33333px 1.33333px 6.66667px rgba(30, 94, 243, 0.2)",
};

export default class Header extends React.Component {
  render() {
    return (
      <header className="header">
        <NavigationBar />
        <Container>
          <Row className="header-container">
            <Col className="order-2 order-lg-1">
              <h1 className="title">Not your average banking experience.</h1>
              <h6 className="subtitle">
                Banking, but seasoned with SALT. <br /> We deal with all things
                related to global <br /> business banking so that you don't have
                to.
              </h6>
              <Button className="getstarted-action d-inline-flex align-items-center text-center" href={SALTLinks.getstarted} variant="primary" size="lg"  style={buttonStyle}>
                Get Started!
              </Button>
            </Col>
            <Col className="order-1 order-lg-2 col-12 col-lg-6">
              <img
                src={WorldMap}
                alt="World Map"
                style={{ width: "100%", height: "auto" }}
              />
            </Col>
          </Row>
        </Container>
        <svg
          className="frame-decoration"
          data-name="Layer 2"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#ffffff"
            fillOpacity="1"
            d="M0,32L48,74.7C96,117,192,203,288,245.3C384,288,480,288,576,266.7C672,245,768,203,864,165.3C960,128,1056,96,1152,112C1248,128,1344,192,1392,224L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </header>
    );
  }
}
