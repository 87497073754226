import React from "react";
import {
  Col,
  Row,
  Modal,
  Button,
  ButtonGroup,
  ToggleButton,
  Alert,
} from "react-bootstrap";
import { collection, addDoc } from "firebase/firestore";

import "./index.css";
import { db } from "../../../App";

const buttonStyle = {
  marginTop: "35px",
  fontFamily: "HK Grotesk",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "400",
  letterSpacing: "0em",
  minHeight: "53px",
  padding: "0 40px",
  borderRadius: "5px",
  background: "linear-gradient(180deg, #1E5EF3 0%, #134FDA 100%)",
  boxShadow: "1.33333px 1.33333px 6.66667px rgba(30, 94, 243, 0.2)",
};

const TableForm = ({ displayForm, setDisplayForm }) => {
  const [alertMsg, setAlertMsg] = React.useState({
    message: undefined,
    type: undefined,
  });

  // const [isLoading, setLoading] = React.useState(false); // Submit button loading feedback

  const handleClose = () => setDisplayForm(false); // Modal Close

  // radio buttons state
  const [radio1Value, setRadio1Value] = React.useState("$100K - $250K");
  const [radio2Value, setRadio2Value] = React.useState("In a month");

  const [isFormSubmitted, setIsFormSubmitted] = React.useState("init");

  // form data state for submission
  const [formData, setFormData] = React.useState({
    name: undefined,
    email: undefined,
    companyName: undefined,
    contact: undefined,
    amountToRaise: radio1Value,
    planningToRaise: radio2Value,
  });

  const radios1 = [
    { name: "$0 - $100K", value: "$0 - $100K" },
    { name: "$100K - $250K", value: "$100K - $250K" },
    { name: "Above $ 250K", value: "Above $ 250K" },
  ];

  const radios2 = [
    { name: "Within a week", value: "Within a week" },
    { name: "In a month", value: "In a month" },
    { name: "Just exploring", value: "Just exploring" },
  ];

  function validateFormData() {
    let emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let contactNumberPattern =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    if (
      formData?.email.toLowerCase().match(emailPattern) &&
      formData?.contact.toLowerCase().match(contactNumberPattern)
    ) {
      return true;
    } else if (formData?.email.toLowerCase().match(emailPattern) === null) {
      setAlertMsg({
        message: "Please enter a valid email address.",
        type: "danger",
      });
      return false;
    } else if (
      formData?.contact.toLowerCase().match(contactNumberPattern) === null
    ) {
      setAlertMsg({
        message: "Please enter a valid contact number.",
        type: "danger",
      });
      return false;
    }
  }

  function isFormDataEmpty() {
    if (
      !formData?.name ||
      !formData?.email ||
      !formData?.companyName ||
      !formData?.contact
    ) {
      return true;
    } else {
      return false;
    }
  }

  async function handleFormSubmit() {
    try {
      if (!isFormDataEmpty()) {
        if (validateFormData()) {
          setAlertMsg({
            message: undefined,
            type: undefined,
          });
          setIsFormSubmitted("pending");
          const filledTime = new Date().toString();
          const requestParams = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              ...formData,
              filled: filledTime,
            }),
          };
          const docRef = await addDoc(collection(db, "tablewaitlist"), {
            ...formData,
            filled: filledTime,
          }).then((result) =>
            fetch(
              "https://bkrqos88ph.execute-api.ap-south-1.amazonaws.com/default/interestMail",
              requestParams
            )
              .then((response) => response.json())
              .then((data) => {
                setIsFormSubmitted("submitted");
              })
          );
        }
      } else {
        setAlertMsg({
          message: "Form fields cannot be empty.",
          type: "danger",
        });
      }
      //   console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
      setAlertMsg({
        message:
          "There was an error submitting your form, Please try again later.",
        type: "danger",
      });
    }
  }

  return (
    <Modal
      show={displayForm}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="form-modal"
    >
      {alertMsg?.message && (
        <Alert
          variant={alertMsg?.type || "danger"}
          onClose={() => {
            setAlertMsg({ message: undefined, type: undefined });
          }}
          // dismissible
        >
          <h5>{alertMsg?.message}</h5>
        </Alert>
      )}
      <Modal.Header
        closeButton
        style={{ borderBottom: "0px solid transparent", paddingBottom: "0px" }}
      >
        <div className="form-title">Get Early Access</div>
      </Modal.Header>
      <Modal.Body style={{ padding: "5px 25px 25px 25px" }}>
        {isFormSubmitted === "init" ? (
          <Row>
            <Col className="col-12 d-flex flex-column">
              <div className="form-label">Name *</div>
              <input
                className="form-input"
                type="text"
                placeholder="Enter Your Name"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    name: e.target.value,
                  });
                }}
              />
            </Col>
            <Col className="col-12 d-flex flex-column">
              <div className="form-label">Email Address *</div>
              <input
                className="form-input"
                type="email"
                placeholder="Enter Your Email"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  });
                }}
              />
            </Col>
            <Col className="col-12 d-flex flex-column">
              <div className="form-label">Company Name *</div>
              <input
                className="form-input"
                type="text"
                placeholder="Enter Company Name"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    companyName: e.target.value,
                  });
                }}
              />
            </Col>
            <Col className="col-12 d-flex flex-column">
              <div className="form-label">Contact Number *</div>
              <input
                className="form-input"
                type="text"
                placeholder="Enter Contact Number"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    contact: e.target.value,
                  });
                }}
              />
            </Col>
            <div className="form-label" style={{ marginTop: "15px" }}>
              What is the amount to be raised? *
            </div>
            <Col className="col-12 d-flex justify-content-center">
              <ButtonGroup style={{ width: "100%" }}>
                {radios1.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`amountToRaise-${idx}`}
                    type="radio"
                    variant={"outline-primary"}
                    name="amountToRaise"
                    value={radio.value}
                    checked={radio1Value === radio.value}
                    onChange={(e) => {
                      setRadio1Value(e.target.value);
                      setFormData({
                        ...formData,
                        amountToRaise: e.target.value,
                      });
                    }}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </Col>
            <div className="form-label" style={{ marginTop: "15px" }}>
              When are you planning to raise? *
            </div>
            <Col className="col-12 d-flex justify-content-center">
              <ButtonGroup style={{ width: "100%" }}>
                {radios2.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`planningToRaise-${idx}`}
                    type="radio"
                    variant={"outline-primary"}
                    name="planningToRaise"
                    value={radio.value}
                    checked={radio2Value === radio.value}
                    onChange={(e) => {
                      setRadio2Value(e.target.value);
                      setFormData({
                        ...formData,
                        planningToRaise: e.target.value,
                      });
                    }}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </Col>
            <div className="d-flex justify-content-center">
              <Button
                className="submittableform-action d-inline-flex align-items-center text-center"
                variant="primary"
                size="lg"
                style={buttonStyle}
                onClick={handleFormSubmit}
              >
                Submit
              </Button>
            </div>
          </Row>
        ) : isFormSubmitted === "pending" ? (
          <div
            style={{
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            Please wait...
          </div>
        ) : (
          isFormSubmitted === "submitted" && (
            <div
              style={{
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Thanks for submitting your contact info! <br />
              Our team will get back to you soon.
            </div>
          )
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TableForm;
