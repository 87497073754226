import * as React from "react"

const ShieldCheck = (props) => (
  <svg
    width={20}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.8 23s8.8-4.4 8.8-11V4.3L9.8 1 1 4.3V12c0 6.6 8.8 11 8.8 11Z"
      fill="#fff"
      stroke="#161616"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="m6.5 12 2.2 2.2 4.4-4.4" fill="#fff" />
    <path
      d="m6.5 12 2.2 2.2 4.4-4.4"
      stroke="#161616"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ShieldCheck
