import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import "./index.css";

export default class HighlightsSection extends React.Component {
  render() {
    return (
      <>
        <div className="highlights-section">
          <Container>
            <Row>
              <Col className="col-12 col-lg-4 mt-5 mb-5 mt-lg-0 mb-lg-0">
                <div className="highlights-title">
                  Smooth foreign <br />
                  transactions
                </div>
                <div className="highlights-subtitle">
                  At the lowest FX rate - <br />
                  1.75 % of the transaction
                </div>
              </Col>
              <Col className="col-12 col-lg-4 mt-5 mb-5 mt-lg-0 mb-lg-0">
                <div className="highlights-title">
                  Deal in 6 <br />
                  currencies
                </div>
                <div className="highlights-subtitle">
                  USD, EUR, <br />
                  GBP, AUD, HKD, SGD
                </div>
              </Col>
              <Col className="col-12 col-lg-4 mt-5 mb-5 mt-lg-0 mb-lg-0">
                <div className="highlights-title">
                  Business globally <br />
                  collect locally
                </div>
                <div className="highlights-subtitle">
                  From more than 50 <br />
                  countries around the world
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
