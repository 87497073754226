import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";
import Footer from "../../Components/Footer";
import NavigationBar from "../../Components/NavigationBar";

import ChairImage from "../../Assets/chair.jpeg";

export default class Chair extends React.Component {
  render() {
    return (
      <div style={{ marginTop: "5rem" }}>
        <NavigationBar />
        <div className="chair-section">
          <Row>
            <Col className="col-12 mt-5 mb-5 mt-lg-0 mb-lg-0">
              <div className="chair-title">We like the way you think. 🧠</div>
            </Col>
          </Row>
        </div>
        <div className="d-flex justify-content-center">
          <img
            src={ChairImage}
            alt="Chair"
            className="img-fluid shadow rounded"
            width="500px"
          />
        </div>

        <div className="chair-section">
          <Row>
            <Col className="col-12 mt-5 mb-5 mt-lg-0 mb-lg-0">
              <div className="chair-title">Why don't you have a chair?</div>
              <div className="chair-subtitle1">
                Pssst.. we are always out there looking for justice fighters for
                cross border payments.
              </div>
              <div className="chair-subtitle">
                Drop your resume to{" "}
                <a href="mailto:careers@salt.pe">careers@salt.pe</a>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    );
  }
}
